<template>
  <div class="cases_detail">
    <div class="cases_detail_tit">
      <p class="tit_strong">
        {{ $t("casesDetail.casesDetail.blh") }}：{{ details.caseNumber }}
      </p>
      <p class="tit_p" style="margin-right: 0.1rem">{{ details.clinicName }}</p>
      <p class="tit_p">{{ details.productName }}</p>
    </div>
    <div class="detail_head">
      <div
        ref="headLeft"
        :class="{ head_left_min_height: !isCommission }"
        class="head_left"
      >
        <div class="head_left_top">
          <div
            :style="{
              backgroundImage: `url('${
                details.userInfo && details.userInfo.photo
                  ? $PicPrefix + details.userInfo.photo
                  : defPhoto
              }')`
            }"
            class="head_l_left"
          ></div>
          <div class="head_l_right">
            <div class="l_right_head">
              <div class="l_right_head_l">
                <div class="l_right_head_l_n">
                  <p
                    :title="get(details, 'userName')"
                    class="l_right_head_l_n_userName txt-ellipsis"
                  >
                    {{ get(details, "userName") || "-" }}
                  </p>
                  <span
                    :style="
                      details.userInfo
                        ? {
                            backgroundImage: `url('${
                              details.userInfo.sex === '1'
                                ? BOY_ICON
                                : GIRL_ICON
                            }')`
                          }
                        : {}
                    "
                    class="head_pic"
                  ></span>
                </div>
                <template v-if="details.id">
                  <div class="case_status">
                    {{ nextStatusObj[details.nextStatus] }}
                  </div>
                </template>
              </div>
            </div>
            <p class="l_right_p">
              <span class="r_sr_icon"></span>
              {{ details.userInfo ? details.userInfo.age : "-" }}
              （{{ details.userInfo ? details.userInfo.birthdayStr : "-" }}）
              <span class="r_m_icon"></span>
              {{ details.userInfo ? details.userInfo.mobile : "-" }}
            </p>
            <div v-if="!productTypeIsBrace" class="l_right_p">
              <span class="r_lc_icon"></span>
              {{ $t("casesDetail.casesDetail.lcqk") }}：
              <div
                v-if="details.clinicInfo"
                :class="{ l_right_p_c_show: titleShow }"
                class="l_right_p_c"
              >
                <span
                  v-for="(infoItem, index) in details.clinicInfo.split(',')"
                  :key="index"
                  class="r_sl_sp"
                  >{{ infoItem }}</span
                >
              </div>
              <span v-else>-</span>
            </div>
            <div
              v-if="split(details.clinicInfo, ',').length > 8"
              class="curp fz14 main_theme_color_333 show_all"
              @click="titleShow = !titleShow"
            >
              {{ $t("cases.cases.xsqb") }}
            </div>
            <div class="head_left_foo">
              <div
                v-if="isRemark"
                :class="lang === 'en_US' ? 'main_big_btn' : 'main_small_btn'"
                class="main_theme_color fz16 curp flex-y-c"
                @click="isRemark = false"
              >
                <img
                  alt=""
                  class="mr4"
                  src="../../../common/imgs/case/case_detail_pen.png"
                  style="display: block; width: 0.24rem"
                />
                {{ $t("casesDetail.casesDetail.tjbz") }}
              </div>
              <div v-if="!isRemark && !isEditRemark" class="add_remark">
                <p class="text_box">
                  <el-input
                    v-model="casesRemark"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    :placeholder="$t('casesDetail.casesDetail.qsr')"
                    class="remark_input"
                    maxlength="200"
                    resize="none"
                    show-word-limit
                    type="textarea"
                  >
                  </el-input>
                </p>
              </div>
              <div v-if="!isRemark && !isEditRemark" class="rem_btns mt20">
                <div class="bc_btn flex-x-y-c curp ml20" @click="saveRemark">
                  {{ $t("casesDetail.casesDetail.bc") }}
                </div>
                <div class="qx_btn flex-x-y-c curp" @click="removeRemark">
                  {{ $t("casesDetail.casesDetail.qx") }}
                </div>
              </div>
              <div v-if="isEditRemark" class="edit_remark">
                <el-tooltip
                  v-if="details.remark"
                  :content="details.remark"
                  placement="top"
                >
                  <div class="remark_p">
                    {{ details.remark }}
                  </div>
                </el-tooltip>

                <p class="edit_txt" @click="toEditRemark">
                  <span class="edit_icon"></span>
                  <span>{{ $t("casesDetail.casesDetail.xg") }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="head_right">
        <div
          v-if="isCommission"
          class="head_right_tit"
          @click="clinicalOperation"
        >
          <template v-if="isCommission">
            <span
              :class="lang === 'en_US' ? 'r_h5_en' : ''"
              :style="lang !== 'zh_CN' ? { 'margin-right': '0' } : {}"
              class="r_h5_icon"
            >
              {{ $t("casesDetail.casesDetail.lc") }}<br />{{
                $t("casesDetail.casesDetail.cz")
              }}
            </span>
            <p
              ref="head_right_p"
              :class="language !== 'zh_CN' ? 'fz16 ' : ''"
              :style="
                language !== 'zh_CN'
                  ? { 'text-align': 'center', 'line-height': '0.2rem' }
                  : {}
              "
              class="head_right_p"
              @mouseenter="informEnter"
              @mouseleave="informLeave"
            >
              {{
                details.status === "abandon"
                  ? $t("casesDetail.casesDetail.yfq")
                  : nextStatusText
              }}
            </p>
          </template>
        </div>
        <ul
          :class="{ mt20: isCommission }"
          :style="{ height: !isCommission ? headRightUl + 'px' : '' }"
          class="head_right_ul"
        >
          <li class="h_r_single" @click="toOperationView('1', details.dataNum)">
            <div :class="lang === 'en_US' ? 'w15fr' : ''" class="h_r_s_left">
              {{ $t("casesDetail.casesDetail.blzl") }}
            </div>
            <div class="h_r_s_right" style="text-align: right">
              <span
                :class="lang === 'en_US' ? 'w1fr' : ''"
                class="s_r_strong"
                >{{ details.dataNum }}</span
              >
              <span :style="lang === 'en_US' ? 'width: 0.85rem' : ''">
                <span class="fz16 main_theme_color_333">{{
                  $t("casesDetail.casesDetail.f")
                }}</span>
                <span class="s_r_to">
                  <img
                    alt=""
                    src="../../../common/imgs/case/arrow_right.png"
                    style="position: relative; top: 2px"
                  />
                </span>
              </span>
            </div>
          </li>
          <li class="h_r_single" @click="toOperationView('2', details.dataNum)">
            <div :class="lang === 'en_US' ? 'w15fr' : ''" class="h_r_s_left">
              {{ $t("casesDetail.casesDetail.yxzl") }}
            </div>
            <div class="h_r_s_right flex-y-c" style="text-align: right">
              <div :class="lang === 'en_US' ? 'w1fr' : ''" class="s_r_strong">
                {{ details.dataNum }}
              </div>
              <div :style="lang === 'en_US' ? 'width: 0.85rem' : ''">
                <span class="fz16 main_theme_color_333">{{
                  $t("casesDetail.casesDetail.z")
                }}</span>
                <span class="s_r_to"
                  ><img
                    alt=""
                    src="../../../common/imgs/case/arrow_right.png"
                    style="position: relative; top: 2px"
                /></span>
              </div>
            </div>
          </li>
          <li
            v-if="!['D', 'G', 'H'].includes(details.cureType)"
            class="h_r_single"
            @click="correctInstructions('1')"
          >
            <component
              :is="lang === 'en_US' ? 'el-tooltip' : 'div'"
              :content="
                details.cureType == 'F'
                  ? $t('casesDetail.casesDetail.fasm')
                  : $t('casesDetail.casesDetail.threedsjsm')
              "
              class="item"
              effect="dark"
              placement="top"
            >
              <div :class="lang === 'en_US' ? 'w15fr' : ''" class="h_r_s_left">
                {{
                  details.cureType == "F"
                    ? $t("casesDetail.casesDetail.fasm")
                    : $t("casesDetail.casesDetail.threedsjsm")
                }}
              </div>
            </component>
            <div class="h_r_s_right" style="text-align: right">
              <span
                :class="lang === 'en_US' ? 'w1fr' : ''"
                class="s_r_strong"
                >{{
                  (details.cureType == "F"
                    ? details.aestheticNoteCount
                    : details.cureNoteCount) || 0
                }}</span
              >
              <span :style="lang === 'en_US' ? 'width: 0.85rem' : ''">
                <span class="fz16 main_theme_color_333">{{
                  $t("casesDetail.casesDetail.g")
                }}</span>
                <span class="s_r_to"
                  ><img
                    alt=""
                    src="../../../common/imgs/case/arrow_right.png"
                    style="position: relative; top: 2px"
                /></span>
              </span>
            </div>
          </li>
          <li
            v-if="!['D', 'G', 'H'].includes(details.cureType)"
            class="h_r_single"
            @click="correctInstructions('2')"
          >
            <component
              :is="lang === 'en_US' ? 'el-tooltip' : 'div'"
              :content="
                details.cureType === 'F'
                  ? $t('casesDetail.casesDetail.mxfa')
                  : $t('casesDetail.casesDetail.threedsj')
              "
              class="item"
              effect="dark"
              placement="top"
            >
              <div :class="lang === 'en_US' ? 'w15fr' : ''" class="h_r_s_left">
                {{
                  details.cureType === "F"
                    ? $t("casesDetail.casesDetail.mxfa")
                    : $t("casesDetail.casesDetail.threedsj")
                }}
              </div>
            </component>
            <div class="h_r_s_right" style="text-align: right">
              <span
                :class="lang === 'en_US' ? 'w1fr' : ''"
                class="s_r_strong"
                >{{ details.cureNoteCount || 0 }}</span
              >
              <span :style="lang === 'en_US' ? 'width: 0.85rem' : ''">
                <span class="fz16 main_theme_color_333">{{
                  $t("casesDetail.casesDetail.g")
                }}</span>
                <span class="s_r_to"
                  ><img
                    alt=""
                    src="../../../common/imgs/case/arrow_right.png"
                    style="position: relative; top: 2px"
                /></span>
              </span>
            </div>
          </li>
          <li
            v-if="details.cureType === 'G'"
            class="h_r_single"
            @click="showDesignList"
          >
            <component
              :is="lang === 'en_US' ? 'el-tooltip' : 'div'"
              :content="$t('casesDetail.casesDetail.zlfa')"
              class="item"
              effect="dark"
              placement="top"
            >
              <div :class="lang === 'en_US' ? 'w15fr' : ''" class="h_r_s_left">
                {{ $t("casesDetail.casesDetail.zlfa") }}
              </div>
            </component>
            <div class="h_r_s_right" style="text-align: right">
              <span :class="lang === 'en_US' ? 'w1fr' : ''" class="s_r_strong">
                {{ designList.length || 0 }}
              </span>
              <span :style="lang === 'en_US' ? 'width: 0.85rem' : ''">
                <span class="fz16 main_theme_color_333">{{
                  $t("casesDetail.casesDetail.g")
                }}</span>
                <span class="s_r_to"
                  ><img
                    alt=""
                    src="../../../common/imgs/case/arrow_right.png"
                    style="position: relative; top: 2px"
                /></span>
              </span>
            </div>
          </li>
          <li class="h_r_single" @click="toOperationView('1', details.dataNum)">
            <div :class="lang === 'en_US' ? 'w15fr' : ''" class="h_r_s_left">
              {{ $t("casesDetail.casesDetail.zlk") }}
            </div>
            <div class="h_r_s_right flex-y-c" style="text-align: right">
              <span
                :class="lang === 'en_US' ? 'w1fr' : ''"
                class="s_r_strong"
                >{{ details.dataNum }}</span
              >
              <span :style="lang === 'en_US' ? 'width: 0.85rem' : ''">
                <span class="fz16 main_theme_color_333">{{
                  $t("casesDetail.casesDetail.f")
                }}</span>
                <span class="s_r_to main_theme_color_333">
                  <img
                    alt=""
                    src="../../../common/imgs/case/arrow_right.png"
                    style="position: relative; top: 2px"
                  />
                </span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div
      v-if="stageProgressList.length && details.cureType !== 'D' && !isLoading"
      class="stage-progress"
    >
      <div class="stage-progress-content">
        <div
          v-for="(item, index) in stageProgressList"
          :key="index"
          :class="[item.passFlag == '0' && 'to-be-confirmed']"
          class="stage-item"
          @click="goCureTarget(item, true)"
        >
          <img
            v-if="item.passFlag == '0'"
            alt=""
            class="stage-bg"
            src="../../../common/imgs/case/active-stage.png"
          />
          <img
            v-else
            alt=""
            class="stage-bg"
            src="../../../common/imgs/case/default-stage.png"
          />
          <div class="item-header">
            {{ renderCaseStage[item.stage] }}
            <span>{{
              `${
                item.phaseAdjustmentNumber !== 0
                  ? "（阶段调整）"
                  : item.reopenNumber !== 0
                  ? "（重启）"
                  : ""
              }`
            }}</span>
            <div v-if="'passFlag' in item" class="status">
              {{
                +item.type === 1
                  ? {
                      0: $t("casesDetail.casesDetail.wqr"),
                      1: $t("casesDetail.casesDetail.yqr"),
                      2: $t("casesDetail.casesDetail.yfk")
                    }[+item.passFlag]
                  : {
                      0: $t("casesDetail.casesDetail.wpz"),
                      1: $t("casesDetail.casesDetail.ypz"),
                      2: $t("casesDetail.casesDetail.yfk")
                    }[+item.passFlag]
              }}
            </div>
            <div v-else>
              <el-tooltip
                class="item"
                content="方案当前处于iRDT端，需要等待iRDT通过后医生才能批准或者提交修改意见"
                effect="dark"
                placement="top"
              >
                <img
                  alt=""
                  class="tips-icon"
                  src="../../../common/imgs/case/case_dia_query.png"
                />
              </el-tooltip>
            </div>
          </div>
          <div class="stage-info">
            <div class="design-date">{{ item.generateDatetime }}</div>
            <div class="stage-name">
              {{
                +item.type === 1
                  ? `${$t("casesDetail.casesDetail.mbwfa")} ${item.number}`
                  : details.cureType === "F"
                  ? `${$t("casesDetail.casesDetail.mxfa")} ${item.number}`
                  : `${$t("casesDetail.casesDetail.tdzlfa")} ${item.number}`
              }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="details.cureType === 'D'" class="detail_step">
      <ul class="step_con_ul">
        <li
          v-for="(item, index) in ProgressList"
          :key="index"
          class="step_con_li"
        >
          <div
            :class="[item.stage ? item.activeImg : item.img]"
            class="con_li_left"
          ></div>
          <div class="con_li_right">
            <h5
              :style="language === 'en_US' ? 'width: 1.8rem' : ''"
              class="con_li_h5"
            >
              {{ item.text }}
            </h5>
            <p v-show="item.nodeDate" class="con_li_p">
              {{ item.nodeDate }}
            </p>
          </div>
        </li>
      </ul>
      <ul class="step_tag_ul step_default_ul">
        <li v-for="(item, index) in ProgressList" :key="index" class="tag_li">
          <span
            :class="[
              item.stage && item.stage.includes('DONE')
                ? 'tag_li_icon_active'
                : item.stage && 'tag_li_icon_wait'
            ]"
            class="tag_li_icon"
            >{{ item.stage ? "" : index + 1 }}</span
          >
          <p
            :class="[
              item.stage && item.stage.includes('DONE') && 'tag_li_line_ok'
            ]"
            :style="productTypeIsBrace ? 'width: 4.95rem' : ''"
            class="tag_li_line"
          ></p>
        </li>
      </ul>
    </div>

    <div v-show="!isLoading" class="detail_con">
      <div class="detail_con_left">
        <ul class="con_left_ul">
          <li
            v-for="(item, index) in leftNodeList"
            :key="`big_${item.title}_${index}`"
            class="con_l_single"
          >
            <div v-if="item.singleList" class="con_l_s">
              <div class="title-box">
                <div class="long_string"></div>
                {{ item.title }}
              </div>
              <div v-if="item.extra" class="extra-box">
                <el-popover placement="top" trigger="click">
                  <div class="tip" @click="item.extraClick">
                    {{ $t("notice.caseDetail.help") }}
                  </div>
                  <img
                    slot="reference"
                    alt=""
                    src="../../../common/imgs/expressage_birdie/how_use.png"
                  />
                </el-popover>
              </div>
            </div>
            <ul v-if="item.singleList" class="con_l_s_box">
              <li
                v-for="(singleItem, index) in item.singleList"
                v-if="
                  !singleItem.hidden &&
                    !singleItem.placeholder &&
                    Object.keys(singleItem).length !== 0
                "
                :key="`single_${singleItem.txt}_${index}`"
                class="l_s_box_single"
                @click="clickAllOption(singleItem)"
              >
                <el-popover
                  v-if="
                    singleItem.url === '/cases/bracesRessiue' &&
                      reissueFlag.aidLogisticsFlag !== '0'
                  "
                  :content="$t('braceReissue.blzzwjsmx')"
                  placement="top"
                  title=""
                  trigger="hover"
                  width="200"
                >
                  <div slot="reference" class="brace-tips"></div>
                </el-popover>
                <el-popover
                  v-if="singleItem.url === '/cases/restartCases'"
                  :content="
                    details['expirationDate']
                      ? `${$t('braceReissue.fwjzrq')} ：${moment(
                          details['expirationDate']
                        ).format('YYYY年MM月DD日')}`
                      : `${$t('braceReissue.fwjzrq')}：--年--月--日`
                  "
                  placement="top"
                  title=""
                  trigger="hover"
                  width="265"
                >
                  <div slot="reference" class="gray-brace-tips"></div>
                </el-popover>
                <el-popover
                  v-if="singleItem.url === '/stage-adjustment'"
                  :content="
                    details['expirationDate']
                      ? `${$t('braceReissue.fwjzrq')} ：${moment(
                          details['expirationDate']
                        ).format('YYYY年MM月DD日')}`
                      : `${$t('braceReissue.fwjzrq')}：--年--月--日`
                  "
                  placement="top"
                  title=""
                  trigger="hover"
                  width="265"
                >
                  <!--                  <div slot="reference" class="gray-brace-tips"></div>-->
                </el-popover>
                <div
                  :data-flag="singleItem.nextProcessingFlag"
                  :data-href="singleItem.url"
                  :data-isHover="Number(singleItem.isHover)"
                  :data-isModal="singleItem.isModal"
                  :data-toStatus="singleItem.toStatus"
                  class="mask_box"
                ></div>
                <div
                  v-if="!singleItem.isHover"
                  :style="lang === 'en_US' ? 'width: 0.95rem' : ''"
                  class="show_tip"
                >
                  {{ singleItem.hoverTxt }}
                </div>
                <span
                  :class="[singleItem.classPrefix]"
                  class="l_s__icon"
                ></span>
                <p class="l_s__p">{{ singleItem.txt }}</p>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="detail_con_right">
        <div class="con_r_single">
          <div class="con_r__header">
            <div class="flex-y-c">
              <div class="long_string"></div>
              {{ $t("casesDetail.casesDetail.wlgz") }}
            </div>
            <div
              v-if="logisticsList.length"
              class="con_r__sp"
              @click="
                goPage(
                  `/cases/allLogistics?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`
                )
              "
            >
              {{ $t("casesDetail.casesDetail.ckqb") }}
            </div>
          </div>
          <div v-if="logisticsList.length" class="con_r__box">
            <div
              v-for="(item, index) in logisticsList"
              :key="item.id"
              :class="{ mt10: index !== 0 }"
              class="con_r__box_l"
            >
              <p>
                {{
                  `${item.logisticsInfo.createDatetime || ""} ${
                    item.logisticsName
                  } ${$t("casesDetail.casesDetail.fh")}`
                }}
              </p>
              <div class="con_r__box_r" @click="toLogistics(item.id)">
                {{ $t("casesDetail.casesDetail.ckwl") }}
              </div>
            </div>
          </div>
          <div v-else class="con_no_data">
            {{ $t("casesDetail.casesDetail.zwwlxx") }}
          </div>
        </div>
        <div class="con_r_single">
          <div class="con_r__header">
            <div class="flex-y-c">
              <div class="long_string"></div>
              {{
                !productTypeIsBrace
                  ? $t("casesDetail.casesDetail.bljd")
                  : details.cureType === "F"
                  ? $t("casesDetail.casesDetail.bljd")
                  : $t("casesDetail.casesDetail.ytjd")
              }}
            </div>
          </div>
          <ul class="con_cases_box">
            <template v-if="!productTypeIsBrace">
              <li class="cases_single">
                <h5
                  class="cases_s_tit"
                  @click="
                    () => {
                      showLogistics('0');
                    }
                  "
                >
                  {{ $t("casesDetail.casesDetail.wcjd") }}
                  <span
                    :class="
                      logisticsShowList.includes('0')
                        ? 'cases_s_tit_u_icon'
                        : 'cases_s_tit_d_icon'
                    "
                  ></span>
                </h5>
                <div
                  v-show="
                    logisticsShowList.includes('0') && caseProgressObj['D']
                  "
                  class="cases_s_con"
                >
                  <p
                    v-for="(item, index) in caseProgressObj['D']"
                    :key="item.node + index"
                    class="cases_s_con_p"
                  >
                    <span
                      >{{ caseProgressObj["D"].length - index }}.{{
                        item.name
                      }}
                      {{ item.time }}</span
                    >
                    <span
                      v-if="nodeToObj[item.node]"
                      class="cases_s_con_sp"
                      @click="goNodePage(item.node)"
                      >{{ nodeToObj[item.node].txt }}</span
                    >
                  </p>
                </div>
              </li>
            </template>
            <li class="cases_single">
              <h5
                class="cases_s_tit"
                @click="
                  () => {
                    showLogistics('1');
                  }
                "
              >
                {{
                  !productTypeIsBrace
                    ? $t("casesDetail.casesDetail.zljd")
                    : $t("casesDetail.casesDetail.scjd")
                }}
                <span
                  :class="
                    logisticsShowList.includes('1')
                      ? 'cases_s_tit_u_icon'
                      : 'cases_s_tit_d_icon'
                  "
                ></span>
              </h5>
              <div
                v-show="logisticsShowList.includes('1') && caseProgressObj['C']"
                class="cases_s_con"
              >
                <p
                  v-for="(item, index) in caseProgressObj['C']"
                  :key="item.node + index"
                  class="cases_s_con_p"
                >
                  <span
                    >{{ caseProgressObj["C"].length - index }}.{{ item.name }}
                    {{ item.time }}</span
                  >
                  <span
                    v-if="nodeToObj[item.node]"
                    class="cases_s_con_sp"
                    @click="goNodePage(item.node)"
                    >{{ nodeToObj[item.node].txt }}</span
                  >
                </p>
              </div>
            </li>
            <li class="cases_single">
              <h5
                class="cases_s_tit"
                @click="
                  () => {
                    showLogistics('2');
                  }
                "
              >
                {{ $t("casesDetail.casesDetail.sjjd") }}
                <span
                  :class="
                    logisticsShowList.includes('2')
                      ? 'cases_s_tit_u_icon'
                      : 'cases_s_tit_d_icon'
                  "
                ></span>
              </h5>
              <div
                v-show="logisticsShowList.includes('2') && caseProgressObj['B']"
                class="cases_s_con"
              >
                <p
                  v-for="(item, index) in caseProgressObj['B']"
                  :key="item.node + index"
                  class="cases_s_con_p"
                >
                  <span
                    >{{ caseProgressObj["B"].length - index }}.{{ item.name }}
                    {{ item.time }}</span
                  >
                  <span
                    v-if="nodeToObj[item.node] && item.refCode"
                    class="cases_s_con_sp"
                    @click="goNodePage(item.node, 'review', item)"
                    >{{ nodeToObj[item.node].txt }}</span
                  >
                </p>
              </div>
            </li>
            <li class="cases_single">
              <h5
                class="cases_s_tit"
                @click="
                  () => {
                    showLogistics('3');
                  }
                "
              >
                {{ $t("casesDetail.casesDetail.sjjd01") }}
                <span
                  :class="
                    logisticsShowList.includes('3')
                      ? 'cases_s_tit_u_icon'
                      : 'cases_s_tit_d_icon'
                  "
                ></span>
              </h5>
              <div
                v-show="logisticsShowList.includes('3') && caseProgressObj['A']"
                class="cases_s_con"
              >
                <p
                  v-for="(item, index) in caseProgressObj['A']"
                  :key="item.node + index"
                  class="cases_s_con_p"
                >
                  <span
                    >{{ caseProgressObj["A"].length - index }}.{{ item.name }}
                    {{ item.time }}</span
                  >
                  <span
                    v-if="nodeToObj[item.node]"
                    class="cases_s_con_sp"
                    @click="goNodePage(item.node, 'operationView')"
                    >{{ nodeToObj[item.node].txt }}</span
                  >
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      v-if="
        Array.isArray(caseListByCaseDetail) && caseListByCaseDetail.length > 1
      "
      :class="[
        'case-list',
        caseListByCaseDetail.length > 2 && 'case-list-ellipis'
      ]"
      :style="{ height: headRightUl + 'px' }"
    >
      <div class="case-list-content">
        <div
          v-for="(item, index) in caseListByCaseDetail.slice(0, 2)"
          v-if="item.productTypeName"
          :key="index"
          :class="[
            'case-list-item',
            details['id'] === item.id && 'active-list-item'
          ]"
          :title="item.productTypeName"
          @click="toOtherCase(item)"
        >
          {{ truncatedText(item.productTypeName) }}
        </div>
        <el-popover
          v-model="morePopperShow"
          placement="bottom-start"
          popper-class="more-list-popper"
          trigger="click"
        >
          <div class="more-list-content">
            <div
              v-for="(item, index) in caseListByCaseDetail.slice(
                2,
                caseListByCaseDetail.length
              )"
              :key="index"
              class="more-list-item"
              @click="toOtherCase(item)"
            >
              {{ item.productTypeName }}
            </div>
          </div>
          <div
            v-if="caseListByCaseDetail.length > 2"
            slot="reference"
            class="case-list-more"
          >
            <img alt="" src="../../../common/imgs/case/case-list-more.png" />
          </div>
        </el-popover>
      </div>
    </div>

    <ExBirdie
      ref="exBirdie"
      :canCreate="true"
      :caseDetail="commonCaseDetail.detail"
      :caseType="details.nextStatus === 'to_deliver_model_remain' ? '2' : '0'"
      :defaultKey="defaultKey"
      :refType="details.nextStatus === 'to_deliver_model_remain' ? '3' : '1'"
      @reload="reloadPage"
    />

    <el-dialog
      :visible.sync="dialogVisible"
      :show-close="false"
      class="suspended-service-one"
      width="7.3rem"
    >
      <div class="dia-header">
        <span>{{
          threeDType === "1"
            ? $t("casesDetail.casesDetail.threedsjsm")
            : details.cureType === "F"
            ? $t("casesDetail.casesDetail.mxfa")
            : $t("casesDetail.casesDetail.threedsj")
        }}</span>
        <img
          @click.stop="dialogVisible = false"
          class="close-dia"
          src="../../../common/imgs/expressage_birdie/dia_close.png"
          alt=""
        />
      </div>
      <div style="height: 3.8rem; overflow-y: scroll">
        <div
          v-for="(item, index) in threeDType === '1' && details.cureType == 'F'
            ? aseList
            : targetList"
          :key="index"
          class="target"
          @click="goCureTarget(item)"
        >
          <div
            :class="{ tac: language === 'en_US' }"
            :style="
              language === 'en_US' ? 'width: 1.9rem;line-height: 0.18rem' : ''
            "
            class="target_title fz14 flex-y-c"
          >
            {{ renderCaseStage[item.stage] }}:{{
              threeDType === "1" && details.cureType == "F"
                ? `${$t("casesDetail.casesDetail.fasm")} ${aseList.length -
                    index}`
                : +item.type === 1
                ? `${$t("casesDetail.casesDetail.mbwfa")} ${item.number}`
                : details.cureType === "F"
                ? `${$t("casesDetail.casesDetail.mxfa")} ${item.number}`
                : `${$t("casesDetail.casesDetail.tdzlfa")} ${item.number}`
            }}
          </div>
          <div
            :style="language === 'en_US' ? 'margin-left: 1.25rem;' : ''"
            class="target_time txt-ellipsis fz14 flex-y-c"
          >
            {{ moment(item.generateDatetime).format("YYYY-MM-DD") }}
          </div>
          <div
            v-show="threeDType === '2'"
            :style="language === 'en_US' ? 'min-width: 1.2rem' : ''"
            class="target_state fz14 flex-y-c"
          >
            {{
              +item.type === 1
                ? {
                    0: $t("casesDetail.casesDetail.wqr"),
                    1: $t("casesDetail.casesDetail.yqr"),
                    2: $t("casesDetail.casesDetail.yfk")
                  }[+item.passFlag]
                : {
                    0: $t("casesDetail.casesDetail.wpz"),
                    1: $t("casesDetail.casesDetail.ypz"),
                    2: $t("casesDetail.casesDetail.yfk")
                  }[+item.passFlag]
            }}
          </div>
        </div>
      </div>
      <!--      <div class="w100 posr mb28" style="height: 36px">
              <div
                class="threeD_cancel flex-x-y-c curp mr20"
                @click="dialogVisible = false"
              >
                {{ $t("common.common.qx") }}
              </div>
            </div>-->
    </el-dialog>
    <el-dialog
      :visible.sync="superadditionVisible"
      center
      width="25%"
      :show-close="false"
      @close="isShowHistory = false"
      class="suspended-service-one"
    >
      <div class="dia-header">
        <span>{{ $t("casesDetail.casesDetail.zjsc") }}</span>
        <img
          @click.stop="superadditionVisible = false"
          class="close-dia"
          src="../../../common/imgs/expressage_birdie/dia_close.png"
          alt=""
        />
      </div>
      <el-form
        v-show="!isShowHistory"
        ref="addProductForm"
        :label-width="language === 'en_US' ? '220px' : '120px'"
        :model="addProductForm"
        :rules="addProductFormRule"
        class="flex-y-c"
        style="margin-top: 20px;"
      >
        <el-form-item
          :label="$t('casesDetail.casesDetail.zjsc')"
          prop="productId"
        >
          <el-select
            v-model="addProductForm.productId"
            :placeholder="$t('common.message.qxz')"
          >
            <el-option
              v-for="(product, index) in commonAddProductList.list"
              :key="index"
              :label="product.name"
              :value="product.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div
          v-if="details.mainFlag === '1'"
          class="curp main_theme_color ml20 look_history"
          @click="lookHistoryList"
        >
          {{ $t("casesDetail.casesDetail.cklszj") }}
        </div>
      </el-form>

      <div v-show="isShowHistory" class="history_list">
        <el-scrollbar style="height: 100%">
          <div
            v-for="(item, index) in addHistoryList.list"
            :key="index"
            class="history_list_item"
          >
            <div>{{ item.productName }}</div>
            <div>{{ item.updateDatetime }}</div>
            <div class="curp" @click="addHistoryJump(item.id)">
              {{ $t("casesDetail.casesChildren.amendments.ckbl") }}
            </div>
          </div>
        </el-scrollbar>
      </div>

      <div class="dia-footer">
        <div
          class="cancel"
          @click="
            isShowHistory
              ? (isShowHistory = false)
              : (superadditionVisible = false)
          "
        >
          {{ $t("cases.theDelivery.qx") }}
        </div>
        <div
          :loading="addProductLoading"
          class="confirm"
          @click="submitAddProduct"
        >
          {{ $t("cases.theDelivery.qr") }}
        </div>
      </div>
    </el-dialog>
    <!--    <el-dialog
          :before-close="closeSwitchVeneer"
          :title="$t('birdie.ts')"
          :visible.sync="showSwitchVeneer"
          class="switch-veneer-dia"
          width="30%"
        >
          <div class="switch-veneer-content">
            <img alt="" src="../../../common/imgs/icon_warn.png" />
            {{ $t("cases.cases.xytjxffab") }}
          </div>
          <span slot="footer" class="switch-veneer-footer">
            <div class="cancel-btn" @click="showSwitchVeneer = false">
              {{ $t("cases.theDelivery.qx") }}
            </div>
            <div class="submit-btn" @click="sureToSwitchVeneer">
              {{ $t("cases.theDelivery.qr") }}
            </div>
            
          </span>
        </el-dialog>-->
    <el-dialog
      :visible.sync="showSwitchVeneer"
      class="suspended-service"
      width="25%"
      :show-close="false"
      :before-close="closeSwitchVeneer"
    >
      <div class="suspended-content">
        <img alt="" src="../../../common/imgs/icon_warn.png" />
        <div class="suspended-title">{{ $t("cases.cases.xytjxffab") }}</div>
        <div slot="footer" class="suspended-footer">
          <div class="cancel-btn" @click="showSwitchVeneer = false">
            {{ $t("cases.theDelivery.qx") }}
          </div>
          <div class="submit-btn" @click="sureToSwitchVeneer">
            {{ $t("cases.theDelivery.qr") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="showSuspended"
      class="suspended-service-one"
      width="30%"
      :show-close="false"
      @before-close="showSuspended = false"
    >
      <div class="dia-header">
        <span>{{ $t("braceReissue.ztfw") }}</span>
        <img
          @click.stop="showSuspended = false"
          class="close-dia"
          src="../../../common/imgs/expressage_birdie/dia_close.png"
          alt=""
        />
      </div>
      <div class="dia-content">
        <div class="suspended-content">
          <img alt="" src="../../../common/imgs/icon_warn.png" />
          <div class="suspended-title">{{ $t("braceReissue.qqdsfjxgcz") }}</div>
          <div class="suspended-tips">
            <!--          <div class="tips-label">{{ $t("braceReissue.ztfw") }}</div>-->
            <div class="tips-value">
              <div>1.{{ $t("braceReissue.ztqrhgjpdsj") }}</div>
              <div>2.{{ $t("braceReissue.mcfhhfkczzt") }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="dia-footer">
        <div class="cancel" @click="showSuspended = false">
          {{ $t("cases.theDelivery.qx") }}
        </div>
        <div class="confirm" @click="sureSuspendedService">
          {{ $t("cases.theDelivery.qr") }}
        </div>
      </div>
    </el-dialog>
    <!--    <confirm-modal
          :confirmObj="{
            tipText: yylTypeTitle[yylType],
            backText: $t('casesDetail.casesChildren.common.qx'),
            okText: $t('cases.casesCreate.qdtj')
          }"
          @confirmSubmit="sureToCreateYyl()"
          @confirmHide="showYylCreated = false"
          v-show="showYylCreated"
        />-->

    <el-dialog
      :visible.sync="showYylCreated"
      class="suspended-service"
      width="25%"
      :show-close="false"
      @before-close="showYylCreated = false"
    >
      <div class="suspended-content">
        <img alt="" src="../../../common/imgs/icon_warn.png" />
        <div class="suspended-title">{{ `${yylTypeTitle[yylType]}` }}</div>
        <div slot="footer" class="suspended-footer">
          <div class="cancel-btn" @click="showYylCreated = false">
            {{ $t("cases.theDelivery.qx") }}
          </div>
          <div class="submit-btn" @click="sureToCreateYyl">
            {{ $t("cases.theDelivery.qr") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showDesign" class="design-dialog" width="7.3rem">
      <div class="design-title">
        {{ $t("casesDetail.casesDetail.zlfa") }}
        <span>如需查看硅胶设计方案，请下载到本地查看</span>
      </div>
      <div style="height: 3.8rem; overflow-y: scroll">
        <div v-for="(item, index) in designList" :key="index" class="target">
          <div
            :class="{ tac: language === 'en_US' }"
            :style="
              language === 'en_US' ? 'width: 1.9rem;line-height: 0.18rem' : ''
            "
            class="target_title fz14 flex-y-c"
          >
            {{ item.name || "" }}
          </div>
          <div
            :style="language === 'en_US' ? 'margin-left: 1.25rem;' : ''"
            class="target_time txt-ellipsis fz14 flex-y-c"
          >
            {{ moment(item.date).format("YYYY-MM-DD") }}
          </div>
          <div
            :style="
              language === 'en_US'
                ? 'min-width: 1.2rem; color: #fcc80e'
                : 'color: #fcc80e'
            "
            class="target_state fz14 flex-y-c"
            @click="downloadDesign(item)"
          >
            下载
          </div>
        </div>
      </div>
      <div class="w100 posr mb28" style="height: 36px">
        <div
          class="threeD_cancel flex-x-y-c curp mr20"
          @click="showDesign = false"
        >
          {{ $t("common.common.qx") }}
        </div>
      </div>
    </el-dialog>
    <ContinueShipping
      :info="details"
      :visible="continueDeliveryVisible"
      @closeContinue="closeContinue"
      @confirmContiune="confirmContiune"
    />
    <FullLoading v-show="isLoading" />
    <init-notice-case
      v-if="showViewInitNotice"
      :hasEditViewControl="hasEditViewControl"
      :noticeKey="noticeKey"
      @closeDrawer="showViewInitNotice = false"
    />
  </div>
</template>

<script>
import $ from "jquery";
import initNoticeCase from "@/components/init-notice-case/index.vue";

import {
  logisticsList,
  deliverAgain,
  PublicCaseAdd,
  PublicLogisticsCasePage,
  caseProgressList,
  casesModifyRemark,
  caseCureNoteList,
  PublicQueryEnvironment,
  queryAesNoteList,
  detailCurePlan,
  caseListCaseDetail,
  queryStageProgressList,
  submitAndTipsFlag,
  supendedService,
  submitSilicone,
  submitOrthodontic,
  submitInvisible,
  transferVeneer,
  curePlanDesignList
} from "common/api/cases";
import { getDictList } from "common/api/public";
import moment from "moment";
import { defPhoto, BOY_ICON, GIRL_ICON } from "common/js/requireImage";
import {
  notifyMsg,
  formatDate,
  downloadFileList,
  shouldShowNotice
} from "common/js/util";
import FullLoading from "components/full-loading/full-loading";
import ContinueShipping from "components/continue-shipping/index.vue";
import TheDelivery from "../cases-create/the-delivery";
import { get, extend, each, map, every, keys, pickBy, split } from "lodash";
import { mapMutations, mapGetters, mapActions, mapState } from "vuex";
import ExBirdie from "../../../components/expressage_birdie/index";
import FileSaver from "file-saver";
import ConfirmModal from "components/confirm-modal/confirm.vue";

let elementResizeDetectorMaker = require("element-resize-detector");

export default {
  data() {
    return {
      // 更新内容
      showViewInitNotice: false,
      hasEditViewControl: true,
      noticeKey: { type: "caseDetail_init_notice", time: "20240322" },

      dialogVisible: false,
      showSwitchVeneer: false,
      BOY_ICON,
      GIRL_ICON,
      defPhoto,
      lang: localStorage.getItem("user_lang") || "zh_CN",
      logisticsShowList: [],
      addLogisticsShowList: [],
      isLoading: false,
      showSuspended: false,
      caseId: "",
      isRemark: true,
      isEditRemark: false,
      casesRemark: "",
      details: {},
      nextStatusObj: {},
      nextStatusText: "",
      logisticsList: [],
      caseProgressObj: {},
      addCaseProgressObj: [],
      caseListByCaseDetail: [],
      morePopperShow: false,
      addModeObj: {},
      doneProgressObj: {},
      statusObj: {
        to_submit: this.$t("casesDetail.casesDetail.dtj"),
        curing: this.$t("casesDetail.casesDetail.zlz"),
        done: this.$t("casesDetail.casesDetail.ywc"),
        archived: this.$t("casesDetail.casesDetail.ygd"),
        abandon: this.$t("casesDetail.casesDetail.yfq")
      },
      statusList: ["to_receive_done", "to_receive", "to_receive_remain"],
      nextOpObj: {
        to_submit: {
          isTo: true,
          url: "/cases/create"
        },
        to_resubmit: {
          isTo: true,
          url: "/cases/create"
        },
        to_confirm_text: {
          isTo: true,
          url: "/cases/view3d"
        },
        to_confirm_plan: {
          isTo: true,
          url: "/cases/view3d"
        },
        to_deliver: {
          isTo: true,
          url: "/cases/nextProduction"
        },
        to_done: {
          isTo: true,
          url: "/cases/maintain"
        },
        to_archive: {
          isTo: true,
          url: "/cases/maintain"
        },
        to_pay_production: {
          isTo: true,
          url: "/cases/paymentScheme"
        }
      },
      curePlanId: "",
      nodeToObj: {
        submit_case: {
          url: "/new/case/data",
          txt: this.$t("casesDetail.casesChildren.amendments.ckbl")
        },
        re_submit_case: {
          url: `/new/case/data`,
          txt: this.$t("casesDetail.casesChildren.amendments.ckbl")
        },
        create_text_plan: {
          url: `/cases/view3d?schemeType=target&curePlanId=${this.$route.query.curePlanId}&caseId=${this.$route.query.caseId}`,
          _blank: "1",
          txt: this.$t("casesDetail.casesChildren.amendments.qdwz")
        },
        confirm_cure_plan: {
          url: `/cases/view3d?schemeType=cure&curePlanId=${this.$route.query.curePlanId}&caseId=${this.$route.query.caseId}`,
          _blank: "1",
          txt: this.$t("casesDetail.casesDetail.ck3d")
        },
        deliver: {
          url: "/cases/lookWearingRecord",
          txt: ""
        }
      },
      status: "",
      clinicDetail: "", //诊所详情
      cureDetail: "", // 方案说明
      continueDeliveryVisible: false,
      leftNodeType: [
        "to_submit",
        "to_resubmit", // 去提交 0 1
        "to_deliver_model", //邮寄硅橡胶 2

        // "to_pay_production", //3
        // "to_text_plan", //4
        // "to_confirm_text", //目标位5

        "to_cure_plan", //6
        "to_confirm_plan", //3d治疗7
        "to_paltform_deliver", //8
        // "to_receive", //9
        "to_deliver", //10 继续发货

        // "to_receive_remain", //11
        // "to_remain", //12生产保持器

        // "to_receive_done", //14
        "to_done", //15完成病例

        "to_deliver_model_remain", //13保持阶段 邮寄硅橡胶
        // "to_paltform_deliver",
        // "to_archive",
        "abandon", // 已废弃
        "archived", //已归档
        "done"
      ],
      //['to_text_plan','to_cure_plan','to_paltform_deliver','to_receive'] //不在左侧列表中的状态
      caseScheduleList: [],
      isDelivery: false,
      targetList: [],
      aseList: [],
      threeDType: "1",
      superadditionVisible: false,
      addProductForm: {
        productId: "",
        id: ""
      },
      addProductFormRule: {
        productId: [
          {
            required: true,
            message: this.$t("common.message.qxz"),
            trigger: "blur"
          }
        ]
      },
      addProductLoading: false,
      addCaseProgressList: [],
      braceList: [
        {
          text: this.$t("casesDetail.casesDetail.sjjd01"),
          img: "def_step01",
          activeImg: "def_step01_active",
          key: "A_DONE"
        },
        {
          text: this.$t("casesDetail.casesDetail.sjjd"),
          img: "def_step02",
          activeImg: "def_step02_active",
          key: "B_DONE"
        },
        {
          text: this.$t("casesDetail.casesDetail.scjd"),
          img: "def_step05",
          activeImg: "def_step05_active",
          key: "C_DONE"
        }
      ],
      braceCaseProgressNodeList: [],
      isShowHistory: false,
      addStage: {},
      caseProgressStageList: [],
      isMailModal: false,
      titleShow: false,
      headRightUl: 0,
      defaultKey: "",
      stageProgressList: [],
      reissueFlag: {},
      yylType: "",
      showYylCreated: false,
      yylTypeTitle: {
        "1": this.$t("yssh.xytjjzqcfb"),
        "2": this.$t("yssh.xytjhdcfb"),
        "3": this.$t("yssh.xytjyxcfb")
      },
      showDesign: false,
      designList: [],
      renderCaseStage: {
        B: this.$t("casesDetail.casesDetail.sjjd"),
        C: this.$t("casesDetail.casesDetail.zljd")
      }
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    truncatedText() {
      return function(val) {
        if (this.language === "en_US") {
          return (
            val
              .split("")
              .slice(0, 5)
              .join("") + "..."
          );
        } else {
          return val;
        }
      };
    },
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail", //病例详情
      commonAddProductList: "getCommonAddProductList", //追加产品列表
      addHistoryList: "getCommonAddHistoryList", //追加产品列表
      caseProgressList: "getCaseProgressList" //追加进度列表
    }),
    ProgressList() {
      return this.braceCaseProgressNodeList;
    },
    isCommission() {
      const statusList = [
        "to_receive_done",
        "to_receive",
        "to_receive_remain",
        "to_pay_production"
      ];
      const {
        nextStatus,
        nextProcessingFlag,
        status,
        receiveNotifyFlag
      } = this.details;
      return (
        nextStatus === "to_deliver_model" ||
        nextStatus === "to_deliver_model_remain" ||
        status === "abandon" ||
        (this.nextOpObj[nextStatus] && nextProcessingFlag === "1") ||
        (statusList.includes(nextStatus) && receiveNotifyFlag === "1")
      );
    },
    leftNodeList() {
      if (this.details.cureType === "D" || this.details.cureType === "F") {
        return [
          {
            title: this.$t("casesDetail.casesDetail.fasj"),
            singleList: [
              {
                txt: this.$t("cases.cases.tjbl"),
                visStatus: [0, 1],
                classPrefix: "tjzlb",
                url: "/cases/create",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              },
              {
                txt: this.$t("casesDetail.casesDetail.gxjyj"),
                visStatus: [2],
                classPrefix: "yjgxj",
                isModal: "1",
                isSkip: "1",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              },
              this.details.cureType === "F" && {
                txt: this.$t("casesDetail.casesDetail.pzmxsjfa"),
                visStatus: [4],
                classPrefix: "qdzlfa",
                url: "/cases/view3d",
                type: "cure",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              }
            ]
          },
          this.details.cureType !== "F"
            ? {
                title: this.$t("casesDetail.casesDetail.scjg"),
                singleList: [
                  {
                    txt: this.$t("casesDetail.casesDetail.zjsc"),
                    classPrefix: "zjsc",
                    nextProcessingFlag: "1",
                    isHover: true,
                    url: "/case/add"
                  },
                  this.details.productSuspendedService == 1
                    ? {
                        txt: this.$t("braceReissue.xtfw"),
                        classPrefix: "ztfw",
                        hoverTxt: this.$t("casesDetail.casesDetail.yzt"),
                        isSuspended: true
                      }
                    : {}
                ]
              }
            : {}
        ];
      } else if (this.details["productSystemType"] === "6") {
        return [
          {
            title: this.$t("casesDetail.casesDetail.fasj"),
            singleList: [
              {
                txt: this.$t("cases.cases.tjbl"),
                visStatus: [0, 1],
                classPrefix: "tjzlb",
                url: "/cases/create",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              },
              {
                txt: this.$t("casesDetail.casesDetail.gxjyj"),
                visStatus: [2],
                classPrefix: "yjgxj",
                isModal: "1",
                isSkip: "1",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              },
              this.details["cureType"] === "B"
                ? {
                    txt: this.$t("casesDetail.casesDetail.pztdfa"),
                    visStatus: [4],
                    classPrefix: "qdzlfa",
                    url: "/cases/view3d",
                    type: "cure",
                    selectedKey: "nextStatus",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
                  }
                : {},
              this.details["curePlanInfo"]?.phaseAdjustmentNumber === 0 &&
              this.details["cureType"] === "B" &&
              this.details["bindIRDTFlag"] === "1"
                ? {
                    txt: this.$t("casesDetail.casesDetail.kqrdt"),
                    classPrefix: "zirdt",
                    url: "/switch-irdt",
                    isHover: this.details["helpFlag"] === "0" ? true : false,
                    hoverTxt: this.$t("casesDetail.casesDetail.ywc")
                  }
                : {}
            ]
          },
          this.details.cureType !== "H"
            ? {
                title: this.$t("casesDetail.casesDetail.scjg"),
                singleList: [
                  {
                    txt: this.$t("casesDetail.casesDetail.jxfh"),
                    visStatus: [6],
                    classPrefix: "jxfh",
                    url: "/cases/nextProduction",
                    selectedKey: "nextStatus",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
                  },
                  ["A", "B", "C", "E", "I"].includes(
                    this.details["cureType"]
                  ) && {
                    txt: this.$t("braceReissue.jjyt"),
                    url: "/cases/bracesRessiue",
                    classPrefix: "jjyt",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
                  }
                ]
              }
            : {},
          {
            title: this.$t("casesDetail.casesDetail.blcz"),
            extra: 1,
            extraClick: () => {
              window.open("/helpCenter?type=22");
            },
            singleList: [
              ["A", "B", "C", "E", "I"].includes(this.details["cureType"])
                ? {
                    txt: this.$t("stageAdjust.jdtz"),
                    visStatus: [7],
                    classPrefix: "jdtz",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    url: "/stage-adjustment"
                  }
                : {},
              {
                txt: this.$t("casesDetail.casesDetail.wcbl"),
                visStatus: [7],
                classPrefix: "wcbl",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                url: "/cases/maintain"
              },
              {
                txt: this.$t("casesDetail.casesDetail.cqbl"),
                classPrefix: "cqbl", //cqbl
                url: "/cases/restartCases",
                nextProcessingFlag: "1",
                isHover: true
              },
              this.details.productSuspendedService == 1
                ? {
                    txt: this.$t("braceReissue.ztfw"),
                    classPrefix: "ztfw",
                    hoverTxt: this.$t("casesDetail.casesDetail.yzt"),
                    isSuspended: true
                  }
                : {},
              this.details["productSystemType"] === "6"
                ? {
                    txt: this.$t("yssh.tjgjjzq"),
                    classPrefix: "gjjzq",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    yylType: "1",
                    isHover: true
                  }
                : {},
              this.details["productSystemType"] === "6"
                ? {
                    txt: this.$t("yssh.tjhdjzq"),
                    classPrefix: "hdjzq",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    yylType: "2",
                    isHover: true
                  }
                : {},
              this.details["productSystemType"] === "6"
                ? {
                    txt: this.$t("yssh.tjyxjzq"),
                    classPrefix: "yxjzq",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    yylType: "3",
                    isHover: true
                  }
                : {}
            ]
          }
        ];
      } else {
        return [
          {
            title: this.$t("casesDetail.casesDetail.fasj"),
            singleList: [
              {
                txt: this.$t("cases.cases.tjbl"),
                visStatus: [0, 1],
                classPrefix: "tjzlb",
                url: "/cases/create",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              },
              {
                txt: this.$t("casesDetail.casesDetail.gxjyj"),
                visStatus: [2],
                classPrefix: "yjgxj",
                isModal: "1",
                isSkip: "1",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              },
              !["G", "H"].includes(this.details["cureType"])
                ? {
                    txt: this.$t("casesDetail.casesDetail.pztdfa"),
                    visStatus: [4],
                    classPrefix: "qdzlfa",
                    url: "/cases/view3d",
                    type: "cure",
                    selectedKey: "nextStatus",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
                  }
                : {},
              this.details["curePlanInfo"]?.phaseAdjustmentNumber === 0 &&
              this.details["bindIRDTFlag"] === "1"
                ? {
                    txt: this.$t("casesDetail.casesDetail.kqrdt"),
                    classPrefix: "zirdt",
                    url: "/switch-irdt",
                    isHover: this.details["helpFlag"] === "0" ? true : false,
                    hoverTxt: this.$t("casesDetail.casesDetail.ywc")
                  }
                : {}
            ]
          },
          {
            title: this.$t("casesDetail.casesDetail.scjg"),
            singleList: [
              {
                txt: this.$t("casesDetail.casesDetail.jxfh"),
                visStatus: [6],
                classPrefix: "jxfh",
                url: "/cases/nextProduction",
                selectedKey: "nextStatus",
                hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
              },
              !["G", "H"].includes(this.details["cureType"])
                ? {
                    txt: this.$t("braceReissue.jjyt"),
                    url: "/cases/bracesRessiue",
                    classPrefix: "jjyt",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj")
                  }
                : {}
            ]
          },
          {
            title: this.$t("casesDetail.casesDetail.blcz"),
            extra: 1,
            extraClick: () => {
              window.open("/helpCenter?type=22");
            },
            singleList: [
              ["A", "B", "C", "E", "I"].includes(this.details["cureType"])
                ? {
                    txt: this.$t("stageAdjust.jdtz"),
                    visStatus: [7],
                    classPrefix: "jdtz",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    url: "/stage-adjustment"
                  }
                : {},
              !["G", "H"].includes(this.details["cureType"])
                ? {
                    txt: this.$t("casesDetail.casesDetail.wcbl"),
                    visStatus: [7],
                    classPrefix: "wcbl",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    url: "/cases/maintain"
                  }
                : {},
              this.details.productSuspendedService == 1
                ? {
                    txt: this.$t("braceReissue.ztfw"),
                    classPrefix: "ztfw",
                    hoverTxt: this.$t("casesDetail.casesDetail.yzt"),
                    isSuspended: true
                  }
                : {},
              {
                txt: this.$t("casesDetail.casesDetail.cqbl"),
                classPrefix: "cqbl", //cqbl
                url: "/cases/restartCases",
                nextProcessingFlag: "1",
                isHover: true
              },
              this.details["cureType"] === "E"
                ? {
                    txt: this.$t("casesDetail.casesDetail.ztm"),
                    isHover: true,
                    classPrefix: "ztm",
                    isShow: true,
                    url: "/cases/create"
                  }
                : {},
              this.details["cureType"] === "H"
                ? {
                    txt: this.$t("yssh.tjgjjzq"),
                    classPrefix: "gjjzq",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    yylType: "1",
                    isHover: true
                  }
                : {},
              this.details["cureType"] === "G"
                ? {
                    txt: this.$t("yssh.tjhdjzq"),
                    classPrefix: "hdjzq",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    yylType: "2",
                    isHover: true
                  }
                : {},
              ["G", "H"].includes(this.details["cureType"])
                ? {
                    txt: this.$t("yssh.tjyxjzq"),
                    classPrefix: "yxjzq",
                    hoverTxt: this.$t("casesDetail.casesDetail.wdsj"),
                    yylType: "3",
                    isHover: true
                  }
                : {}
            ]
          }
        ];
      }
    },
    productTypeIsBrace() {
      return this.details.cureType === "D" || this.details.cureType === "F";
    },
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    }
  },
  methods: {
    moment,
    get,
    keys,
    split,
    downloadDesign(item) {
      const loading = this.$loading({
        lock: true,
        text: "正在下载...",
        spinner: "el-icon-loading",
        customClass: "download-mask",
        background: "rgba(0, 0, 0, 0.7)"
      });
      FileSaver.saveAs(
        this.$PicPrefix + item.file,
        `${this.details["caseNumber"]}_${
          this.details["userName"]
        }_${this.moment(new Date()).format("YYYYMMDD")}.zip`
      );
      loading.close();
    },
    showDesignList() {
      if (this.designList.length) {
        this.showDesign = true;
      }
    },
    ...mapActions({
      getCommonCaseDetail: "actionGetCommonCaseDetail", //病例详情
      getCommonAddProductList: "actionGetCommonAddProductList", //追加产品列表
      getAddHistoryList: "actionGetAddHistoryList", //追加历史列表
      getCaseProgressList: "actionGetCaseProgressList" //追加进度列表
    }),
    ...mapMutations(["HANDLE_MSG"]),
    closeContinue() {
      this.continueDeliveryVisible = false;
    },
    sureToSwitchVeneer() {
      transferVeneer({ id: this.details["id"] }).then(res => {
        this.$router.push(`/cases/create?caseId=${res}`);
      });
    },
    sureToCreateYyl() {
      const yylApi = {
        "1": submitSilicone,
        "2": submitOrthodontic,
        "3": submitInvisible
      }[this.yylType];
      yylApi({
        id: this.caseId
      }).then(res => {
        notifyMsg(this, "success", this.$t("common.message.czcg"));
        this.$router.replace({ path: `/cases/create?caseId=${res}` });
      });
    },
    sureSuspendedService() {
      supendedService(this.details["id"])
        .then(res => {
          this.showSuspended = false;
          this.reloadPage();
          notifyMsg(this, "success", this.$t("common.message.czcg"));
        })
        .catch(err => {
          this.showSuspended = false;
        });
    },
    closeSwitchVeneer() {
      this.showSwitchVeneer = false;
    },
    curePlanDetail() {
      detailCurePlan(this.curePlanId).then(data => {
        this.defaultKey = data["siliconeRubberModel"];
      });
    },
    toOtherCase(val) {
      if (val.id === this.details["id"]) return;
      this.changeRoute({
        caseId: val.id
      });
      this.$router.replace(`/cases/detail?caseId=${val.id}`);
      this.caseId = val.id;
      this.getPageData();
      this.getCaseCureNoteList();
      this.queryStageProgressList();
      this.morePopperShow = false;
    },
    queryStageProgressList() {
      queryStageProgressList({ caseId: this.caseId }).then(res => {
        this.stageProgressList = res;
      });
    },
    changeRoute(args) {
      let route = this.$route;
      this.$router.replace({
        name: route.name,
        query: extend({}, route.query, args)
      });
    },
    reloadPage() {
      this.getPageData();
      this.getCaseCureNoteList();
      this.queryStageProgressList();
    },
    correctInstructions(type) {
      this.details.cureNoteCount > 0 ? (this.dialogVisible = true) : "";
      this.threeDType = type;
    },
    getCaseCureNoteList() {
      let { caseId } = this.$route.query;
      return caseCureNoteList({ caseId }).then(data => {
        this.targetList = data;
      });
    },
    //继续发货
    confirmContiune(info) {
      if (this.isDelivery) return;
      this.isDelivery = true;
      if (info["receiveType"] === "2") {
        info["province"] = info["provinces"][0];
        info["city"] = info["provinces"][1];
        info["area"] = info["provinces"][2];
        info["receiverMobile"] = info["phone"];
        delete info["provinces"];
        delete info["phone"];
      }

      deliverAgain({ id: this.curePlanId, ...info })
        .then(() => {
          this.isDelivery = false;
          this.getCasesDetail(true).then(data => {
            this.getLeftNodeType(data.nextStatus).then(() => {
              let route = this.$route;
              this.$router.replace({
                name: route.name,
                query: extend({}, route.query, { delivery: "" })
              });
              this.continueDeliveryVisible = false;
              notifyMsg(this, "success", this.$t("common.message.czcg"));
            });
          });
        })
        .catch(() => {
          this.isDelivery = false;
        });
    },
    goCureTarget(item, boolean) {
      let type = item.type === "1" ? "target" : "cure";
      const locale = this.lang
        ? this.lang === "zh_CN"
          ? "zh-cn"
          : "en-us"
        : "zh_CN";
      if (this.threeDType !== "1" || boolean) {
        if (this.details.cureType === "F") {
          window.open(
            "/exocad" +
              `?schemeType=${type}&curePlanId=${this.curePlanId}&caseId=${this.$route.query.caseId}&cureNoteId=${item.id}`,
            "_blank"
          );
        } else {
          window.open(
            "/cases/view3d" +
              `?schemeType=${type}&curePlanId=${this.curePlanId}&caseId=${this.$route.query.caseId}&cureNoteId=${item.id}`,
            "_blank"
          );
        }
      }
      if (this.threeDType === "1") {
        PublicQueryEnvironment({ key: "environment" }).then(data => {
          let str = get(data, "[0].value");
          if (this.details.cureType == "F") {
            let url = "";
            if (str == "online") {
              url = "http://sys.yueyashidai.com/";
            } else {
              url = `http://sys.${str}.yueyashidai.com/`;
            }
            window.open(
              `${url}common/aestheticPlan?curePlanId=${
                item.curePlanId
              }&cureNoteId=${item.cureNoteId}&token=${localStorage.getItem(
                "token"
              )}`,
              "_blank"
            );
          } else {
            let url = "";
            if (str == "online") {
              url = "http://sys.aismile.cn/";
            } else {
              url = `http://sys.${str}.aismile.cn/`;
            }
            window.open(
              `${url}printview` +
                `?type=1&v=new&cureNoteId=${
                  item.id
                }&token=${localStorage.getItem("token")}&locale=${locale}&noApp=1`,
              "_blank"
            );
          }
        });
      }
    },
    informEnter() {
      const statusList = ["to_receive_done", "to_receive", "to_receive_remain"];
      const {
        nextStatus,
        nextProcessingFlag,
        status,
        receiveNotifyFlag,
        wearNotifyFlag
      } = this.details;
      if (
        nextStatus === "to_deliver_model" ||
        nextStatus === "to_deliver_model_remain" ||
        wearNotifyFlag === "1" ||
        status === "abandon" ||
        (this.nextOpObj[nextStatus] && nextProcessingFlag === "1") ||
        (statusList.includes(nextStatus) && receiveNotifyFlag === "1")
      ) {
        // this.$refs['head_right_p'].style.color = "#FEC111";
      }
    },
    informLeave() {
      // this.$refs['head_right_p'].style.color = "#555657";
    },
    showLogistics(key) {
      const index = this.logisticsShowList.indexOf(key);
      if (index > -1) {
        this.logisticsShowList.splice(index, 1);
      } else {
        this.logisticsShowList.push(key);
      }
    },
    addShowLogistics(key, type) {
      const index = this.addModeObj[type].indexOf(key);
      if (index > -1) {
        this.addModeObj[type].splice(index, 1);
      } else {
        this.addModeObj[type].push(key);
      }
    },
    changeQuery(arg) {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, route.query, arg)
      });
    },
    getCasesDetail(isNext) {
      // 病例详情
      this.casesRemark = "";
      return this.getCommonCaseDetail(this.caseId)
        .then(data => {
          if (data["cureType"] === "G") {
            curePlanDesignList({ caseId: data["id"] }).then(_design => {
              this.designList = _design;
            });
          }
          this.changeRoute({
            curePlanId: data.currentCurePlanId || ""
          });
          this.curePlanId = data.currentCurePlanId;
          caseListCaseDetail({
            userId: data["userId"]
          }).then(res => {
            let list = res.filter(it => it.hasOwnProperty("productTypeName"));
            const current = list.findIndex(it => it.id === this.caseId);
            if (current > 1) {
              list[current] = list.splice(1, 1, list[current])[0];
            }
            this.caseListByCaseDetail = list;
            this.getLeftNodeType(get(data, "nextStatus"), isNext);
          });
          this.queryProgressRender();
          this.isMailModal =
            +data.nextProcessingFlag === 1 &&
            ["to_deliver_model_remain", "to_deliver_model"].includes(
              data.nextStatus
            );
          this.isLoading = false;
          this.details = data;
          if (this.details.cureType === "F") {
            this.getAesNoteList();
          }
          this.$router.push({
            query: { ...this.$route.query, productType: data["cureType"] }
          });
          document.title = data.userName;
          this.status = get(data, "curePlanInfo.status");
          const { stage = "" } = data;
          if (stage === "D") {
            this.logisticsShowList.push("0");
          } else if (stage === "C") {
            this.logisticsShowList.push("1");
          } else if (stage === "B") {
            this.logisticsShowList.push("2");
          } else {
            this.logisticsShowList.push("3");
          }
          const { remark } = data;
          this.nextStatusText = this.nextStatusObj[get(data, "nextStatus")];
          if (remark) {
            this.isRemark = false;
            this.isEditRemark = true;
            this.casesRemark = remark;
          }
          return Promise.resolve(data);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getLeftNodeType(nextStatus, isNext) {
      let leftTypeList = this.leftNodeType;
      let index;
      const { curePlanInfo } = this.details;
      const { phaseAdjustmentNumber } = curePlanInfo;
      each(leftTypeList, (item, idx) => {
        if (item === nextStatus) {
          index = idx;
        }
      });
      each(this.leftNodeList, it => {
        if (it.singleList) {
          const reopenList = ["to_deliver", "done", "to_done"];
          each(it.singleList, item => {
            if (item["yylType"] && this.details["productSystemType"] === "6") {
              switch (item["yylType"]) {
                case "1":
                  if (this.details["siliconeFlag"] === "1") {
                    item["isHover"] = false;
                    item["classPrefix"] = "gjjzq" + "_black";
                    item["hoverTxt"] = this.$t("casesDetail.casesDetail.ywc");
                  } else {
                    item["isHover"] = true;
                  }
                  break;
                case "2":
                  if (this.details["orthodonticFlag"] === "1") {
                    item["isHover"] = false;
                    item["classPrefix"] = "hdjzq" + "_black";
                    item["hoverTxt"] = this.$t("casesDetail.casesDetail.ywc");
                  } else {
                    item["isHover"] = true;
                  }
                  break;
                case "3":
                  if (this.details["invisibleFlag"] === "1") {
                    item["isHover"] = false;
                    item["classPrefix"] = "yxjzq" + "_black";
                    item["hoverTxt"] = this.$t("casesDetail.casesDetail.ywc");
                  } else {
                    item["isHover"] = true;
                  }
                  break;
              }
              return;
            }
            if (item["isSuspended"]) {
              if (
                (this.details["nextStatus"] == "to_deliver" ||
                  this.details["nextStatus"] == "to_done") &&
                this.details["suspendedService"] !== "1"
              ) {
                item["isHover"] = true;
              } else {
                item["isHover"] = false;
                item["classPrefix"] = "ztfw" + "_gray";
                item["hoverTxt"] =
                  this.details["suspendedService"] !== "1"
                    ? this.$t("casesDetail.casesDetail.wdsj")
                    : this.$t("casesDetail.casesDetail.yzt");
              }
              return;
            }
            if (item["url"] === "/cases/bracesRessiue") {
              // 重启次数等于0并且无当前发货信息禁用其余条件下放开
              if (
                get(this.details.curePlanInfo, "nowStepUpjaw") == 0 &&
                get(this.details.curePlanInfo, "nowStepDownjaw") == 0 &&
                this.details["curePlanInfo"].reopenNumber == 0
              ) {
                item["isHover"] = false;
                item["classPrefix"] = "jjyt" + "_gray";
                item["hoverTxt"] = this.$t("casesDetail.casesDetail.wdsj");
                return;
              } else {
                item["isHover"] = true;
                return;
              }
            }
            if (item["url"] === "/switch-irdt") {
              const statusList = [
                "to_deliver_model",
                "to_agent_scan",
                "to_agent_rescan",
                "to_doctor_confirm_plan"
              ];
              if (this.details["helpFlag"] === "1") {
                item["classPrefix"] = "zirdt" + "_black";
                return;
              } else {
                if (!statusList.includes(this.details["curePlanInfo"].status)) {
                  item["classPrefix"] = "zirdt" + "_gray";
                  item["isHover"] = false;
                  item["hoverTxt"] = this.$t("casesDetail.casesDetail.wdsj");
                }
              }
              return;
            }
            if (item.isShow) {
              if (
                this.details["number"] !== 2 &&
                this.details["status"] === "done" &&
                this.caseListByCaseDetail.length < 2
              ) {
                item.classPrefix = "ztm";
              } else {
                item.isHover = false;
                if (this.caseListByCaseDetail.length > 1) {
                  item.classPrefix = "ztm_black";
                  item["hoverTxt"] = this.$t("casesDetail.casesDetail.ywc");
                } else {
                  item.classPrefix = "ztm_gray";
                  item["hoverTxt"] = this.$t("casesDetail.casesDetail.wdsj");
                }
              }
              return;
            }
            if (item.url === "/cases/restartCases") {
              if (
                phaseAdjustmentNumber !== 0 &&
                ![...reopenList, "to_confirm_plan"].includes(
                  this.details.nextStatus
                )
              ) {
                item["classPrefix"] = "cqbl_gray";
                item["isHover"] = true;
              }
              if (
                phaseAdjustmentNumber === 0 &&
                !reopenList.includes(this.details.nextStatus)
              ) {
                item["classPrefix"] = "cqbl_gray";
                item["isHover"] = true;
              }
            }
            if (
              item.url !== "/cases/restartCases" &&
              this.details.stage === "D"
            ) {
              if (this.details.nextStatus === "to_paltform_deliver") {
                item["classPrefix"] = item["classPrefix"] + "_black";
                item["isHover"] = false;
                item["hoverTxt"] = this.$t("casesDetail.casesDetail.ywc");
                return;
              }
            }
            if (
              item.url === "/case/add" &&
              this.details.nextStatus === "to_submit"
            ) {
              item["classPrefix"] = "zjsc_gray";
              item["isHover"] = true;
            }
            if (
              (item.url === "/cases/nextProduction" ||
                item.url === "/cases/maintain" ||
                item.url === "/stage-adjustment") &&
              every(item.visStatus, num => num < index)
            ) {
              if (this.details.stage === "D") {
                item["classPrefix"] =
                  item["classPrefix"] + (isNext ? "" : "_black");
                item["isHover"] = false;
                item["hoverTxt"] = this.$t("casesDetail.casesDetail.ywc");
                return;
              }
            }
            if (item.visStatus) {
              if (every(item.visStatus, num => num < index)) {
                item["isHover"] = false;
                item["classPrefix"] =
                  item["classPrefix"] + (isNext ? "" : "_black");
                item["hoverTxt"] = this.$t("casesDetail.casesDetail.ywc");
              } else if (every(item.visStatus, num => num > index)) {
                item["isHover"] = false;
                item["classPrefix"] =
                  item["classPrefix"] + (isNext ? "" : "_gray");
                item["hoverTxt"] = this.$t("casesDetail.casesDetail.wdsj");
              } else if (item.visStatus.includes(index)) {
                item["classPrefix"] = item["classPrefix"];
                item["isHover"] = true;
              }
            }
          });
        }
      });

      if (this.details.nextStatus === "to_deliver") {
        each(this.leftNodeList, it => {
          each(it.singleList, item => {
            if (item.url === "/stage-adjustment") {
              item["classPrefix"] = "jdtz";
              item["isHover"] = true;
            }
            if (item.url == "/cases/maintain") {
              item["classPrefix"] = "wcbl";
              item["isHover"] = true;
            }
          });
        });
      }
      return Promise.resolve();
    },
    addHistoryJump(id) {
      this.$router.replace({ path: "/cases/detail", query: { caseId: id } });
      this.$router.go(0);
    },
    toLogistics(id) {
      this.goPage(
        `/cases/viewLogistics?caseId=${this.queryCaseId}&curePlanId=${this.queryCurePlanId}&logisticsId=${id}`
      );
    },
    goNodePage(node, origin, item) {
      let { url, _blank = "" } = this.nodeToObj[node];
      if (["confirm_cure_plan", "create_text_plan"].includes(node)) {
        if (this.details.cureType === "F") {
          window.open(
            `/exocad?schemeType=cure&curePlanId=${
              this.$route.query.curePlanId
            }&caseId=${this.$route.query.caseId}&cureNoteId=${get(
              item,
              "refCode"
            )}`,
            "_blank"
          );
          return;
        } else {
          url = url + `&cureNoteId=${get(item, "refCode")}`;
        }
      }
      if (origin === "operationView") {
        sessionStorage.setItem("operationKey", "3");
      }
      if (_blank) {
        localStorage.setItem("hideHead", "1");
        return url && window.open(url, "_blank");
      }
      if (url === "/new/case/data") {
        url =
          url +
          `?caseId=${
            item ? item.caseId : this.$route.query.caseId
          }&curePlanId=${
            item ? item.currentCurePlanId : this.details.currentCurePlanId
          }&tabKey=1`;
      }
      return url && this.$router.push(url);
    },
    goBlankPage(url) {
      localStorage.setItem("hideHead", "1");
      return url && window.open(url, "_blank");
    },
    goPage(url, origin) {
      if (origin === "operationView") {
        sessionStorage.setItem("operationKey", "3");
      } else if (origin === "review") {
        sessionStorage.setItem("isView", "1");
      }
      return url && this.$router.push(url);
    },
    lookHistoryList() {
      this.getAddHistoryList({ id: this.details.id }).then(() => {
        this.isShowHistory = true;
      });
    },
    addProduction() {
      this.getCommonAddProductList({
        platformType: "0",
        type: "2",
        clinicId: this.details.clinicId,
        caseId: this.details["id"]
      }).then(() => {
        this.superadditionVisible = true;
      });
    },
    submitAddProduct() {
      if (this.isShowHistory) {
        return (this.isShowHistory = false);
      }
      this.$refs["addProductForm"].validate(value => {
        if (value) {
          this.$confirm(this.$t("common.message.sfjx"), "", {
            showClose: false,
            confirmButtonText: this.$t(
              "casesDetail.casesChildren.casesDoKeep.qd"
            ),
            cancelButtonText: this.$t(
              "casesDetail.casesChildren.casesDoKeep.qx"
            ),
            type: "warning"
          }).then(() => {
            this.addProductLoading = true;
            this.addProductForm.id = this.details.id;
            PublicCaseAdd(extend({}, this.addProductForm)).then(data => {
              this.superadditionVisible = false;
              this.$message.success(this.$t("common.message.czcg"));
              this.$router.replace({ path: `/cases/create?caseId=${data}` });
            });
          });
        }
      });
    },
    clickAllOption(item) {
      // console.log(item);
      // console.log(this.leftNodeList);
      // 生产流程操作
      let query = `?caseId=${this.caseId}&curePlanId=${this.curePlanId || ""}`;
      const { url, isHover, isModal } = item;
      const reopenList = ["to_deliver", "done", "to_done"];
      const { curePlanInfo, nextStatus } = this.details;
      const { phaseAdjustmentNumber } = curePlanInfo;
      if (
        item["isSuspended"] &&
        (this.details["nextStatus"] == "to_deliver" ||
          this.details["nextStatus"] == "to_done") &&
        this.details["suspendedService"] !== "1"
      ) {
        this.showSuspended = true;
        return;
      }
      if (item["yylType"] && item["isHover"]) {
        this.yylType = item["yylType"];
        this.showYylCreated = true;
        return;
      }

      if (url === "/cases/bracesRessiue") {
        if (!isHover) {
          return notifyMsg(
            this,
            "warning",
            this.$t("casesDetail.casesDetail.gztbkcz"),
            3000
          );
        } else {
          return this.$router.push(
            `/cases/bracesReissue?caseId=${this.caseId}&curePlanId=${this
              .curePlanId || ""}&aidLogisticsFlag=${
              this.reissueFlag.aidLogisticsFlag
            }&isAllowedSubmit=${
              this.reissueFlag.isAllowedSubmit
            }&reissueId=${this.reissueFlag.unSubReissueId || ""}${
              this.reissueFlag.logisticsReissueId
                ? `&logisticsReissueId=${this.reissueFlag.logisticsReissueId}`
                : ""
            }`
          );
        }
      }

      if (
        url === "/cases/create" &&
        phaseAdjustmentNumber !== 0 &&
        nextStatus === "to_resubmit" &&
        isHover
      ) {
        return window.open(
          `/stage-adjustment?caseId=${this.caseId}&curePlanId=${this.curePlanId}`
        );
      }

      if (
        url === "/cases/create" &&
        item.isShow &&
        this.details["number"] !== 2 &&
        this.details["status"] === "done"
      ) {
        if (this.caseListByCaseDetail.length > 1) {
          return;
        } else {
          this.showSwitchVeneer = true;
          return;
        }
      }
      if (url === "/switch-irdt") {
        const statusList = [
          "to_deliver_model",
          "to_agent_scan",
          "to_agent_rescan",
          "to_doctor_confirm_plan"
        ];
        if (
          statusList.includes(this.details["curePlanInfo"].status) &&
          this.details["helpFlag"] === "0"
        ) {
          this.$router.push(url + query);
        }
        return;
      }
      if (url === "/switch-irdt") {
        const statusList = [
          "to_deliver_model",
          "to_agent_scan",
          "to_agent_rescan",
          "to_doctor_confirm_plan"
        ];
        if (
          statusList.includes(this.details["curePlanInfo"].status) &&
          this.details["helpFlag"] === "0"
        ) {
          this.$router.push(url + query);
        }
      }

      if (url === "/cases/restartCases") {
        if (
          phaseAdjustmentNumber !== 0 &&
          ![...reopenList, "to_confirm_plan"].includes(this.details.nextStatus)
        ) {
          return;
        }
        if (
          phaseAdjustmentNumber === 0 &&
          !reopenList.includes(this.details.nextStatus)
        ) {
          return;
        }
      }

      if (url === "/case/add" && this.details.nextStatus === "to_submit")
        return;

      if (url === "/case/add") {
        return this.addProduction();
      }

      if (url === "/cases/maintain") {
        query += `&productId=${this.details["productId"]}`;
      }

      if (["/cases/view3d"].includes(url)) {
        if (this.details.cureType == "F" && isHover) {
          query += `&schemeType=${item.type}&cureNoteId=${this.details.cureNoteId}`;
          window.open("/exocad?" + query);
          return;
        } else {
          query += `&schemeType=${item.type}&cureNoteId=${this.details.cureNoteId}`;
        }
      }

      if (isHover && isModal) {
        return isModal === "1" && this.sendExpress();
      }
      if (url === "/cases/nextProduction") {
        // 继续发货由跳转改弹层
        if (this.details.nextStatus === "to_deliver") {
          return this.getContinueDeliveryDetail();
        }
      }
      if (url && isHover) {
        if (url === "/cases/create") {
          localStorage.setItem("casesTabKey", "0");
          window.open(url + query, "_blank");
        } else {
          console.log('url',url)
          console.log('query',query)
          this.$router.push(url + query);
        }
      } else if (!isHover) {
        notifyMsg(
          this,
          "warning",
          this.$t("casesDetail.casesDetail.gztbkcz"),
          3000
        );
      }
    },
    async getContinueDeliveryDetail() {
      let { receiveAddress } = this.details;
      this.clinicDetail = receiveAddress;
      return (this.continueDeliveryVisible = true);
    },
    removeRemark() {
      const { remark } = this.details;
      if (remark) {
        this.isEditRemark = true;
      } else {
        this.isRemark = true;
      }
    },
    toEditRemark() {
      this.isEditRemark = false;
    },
    saveRemark() {
      // 保存备注
      if (!this.casesRemark) {
        return notifyMsg(
          this,
          "warning",
          this.$t("casesDetail.casesDetail.qtxbz")
        );
      }
      this.isLoading = true;
      casesModifyRemark({
        id: this.caseId,
        remark: this.casesRemark
      })
        .then(() => {
          this.isLoading = false;
          notifyMsg(this, "success", this.$t("casesDetail.casesDetail.bztjcg"));
          this.isEditRemark = true;
          this.getCasesDetail();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    toOperationView(key, val) {
      if (val && +val > 0) {
        this.$router.push({
          name: "new_case_data",
          query: {
            curePlanId: this.details.curePlanInfo.id,
            caseId: this.$route.query.caseId,
            tabKey: key
          }
        });
      }
    },
    clinicalOperation() {
      // 临床操作
      const {
        nextStatus,
        nextProcessingFlag,
        status,
        receiveNotifyFlag,
        cureNoteId,
        cureType,
        productId,
        curePlanInfo
      } = this.details;

      const { phaseAdjustmentNumber } = curePlanInfo;

      let query = `?caseId=${this.caseId}&curePlanId=${this.curePlanId ||
        ""}&cureNoteId=${cureNoteId}`;

      //支付加工费
      if (nextStatus === "to_pay_production") {
        query += "&payType=1";
      }
      if (nextStatus === "to_archive") {
        query += "&type=archive";
      }
      if (nextStatus === "to_done") {
        query += `&productId=${productId}`;
      }
      if (["to_confirm_plan", "to_confirm_text"].includes(nextStatus)) {
        if (cureType == "F") {
          let type = nextStatus === "to_confirm_text" ? "target" : "cure";
          query += `&schemeType=${type}`;
          window.open("/exocad?" + query);
          return;
        } else {
          query += `&schemeType=${
            { to_confirm_plan: "cure", to_confirm_text: "target" }[nextStatus]
          }`;
        }
      }
      if (
        nextStatus === "to_deliver_model" ||
        nextStatus === "to_deliver_model_remain"
      ) {
        return this.sendExpress();
      }
      if (status === "abandon") {
        // return this.$router.push(`/cases/restartCases` + query);
      }
      const statusList = ["to_receive_done", "to_receive", "to_receive_remain"];
      if (statusList.includes(nextStatus)) {
        if (receiveNotifyFlag === "1") {
          return this.$router.push("/cases/allLogistics" + query);
        } else {
          return notifyMsg(
            this,
            "warning",
            this.$t("casesDetail.casesDetail.gztbkcz"),
            3000
          );
        }
      }
      if (phaseAdjustmentNumber !== 0 && nextStatus === "to_resubmit") {
        return window.open(
          `/stage-adjustment?caseId=${this.caseId}&curePlanId=${this.curePlanId}`
        );
      }
      if (this.nextOpObj[nextStatus] && nextProcessingFlag === "1") {
        const { isTo, url } = this.nextOpObj[nextStatus];
        if (nextStatus === "to_submit" || nextStatus === "to_resubmit") {
          return window.open(url + query, "_blank");
        }
        if (nextStatus === "to_deliver") {
          return (this.continueDeliveryVisible = true);
        }
        return isTo && this.$router.push(url + query);
      } else {
        notifyMsg(
          this,
          "warning",
          this.$t("casesDetail.casesDetail.gztbkcz"),
          3000
        );
      }
    },
    sendExpress() {
      this.$refs["exBirdie"].openDia().then(() => {
        this.$refs["exBirdie"] &&
          this.$refs["exBirdie"].resetClinic(
            this.commonCaseDetail.detail.clinicId
          );
      });
    },
    seRemove() {
      sessionStorage.removeItem("operationKey");
      sessionStorage.removeItem("textPlanKey");
      sessionStorage.removeItem("windowName");
      sessionStorage.removeItem("casesTabKey");
      sessionStorage.removeItem("createUserForm");
      sessionStorage.removeItem("diagnosisParams");
    },
    getAesNoteList() {
      let { caseId } = this.$route.query;
      return queryAesNoteList(caseId).then(data => {
        this.aseList = data;
      });
    },
    queryProgressRender() {
      caseProgressList({
        caseId: this.caseId,
        doneFlag: "1"
      }).then(data => {
        this.$forceUpdate();
        const obj = {};
        data.forEach(item => {
          const stage = item.stage;
          obj[stage] = item;
        });
        this.doneProgressObj = obj;
        let ProgressList = this.braceCaseProgressNodeList;
        let typeList = ["A", "B", "C", "D"];
        if (data.length) {
          each(ProgressList, (it, idx) => {
            if (obj.hasOwnProperty(it.key)) {
              it.stage = it.key;
              it.nodeDate = obj[it.key].nodeDate;
            } else {
              if (
                ProgressList[idx - 1].stage &&
                ProgressList[idx - 1].stage.includes("DONE")
              ) {
                ProgressList[idx].stage = typeList[idx];
              }
            }
          });
        } else {
          ProgressList[0].stage = typeList[0];
        }
      });
      caseProgressList({
        caseId: this.caseId,
        sort: "-nodeDatetime"
      }).then(data => {
        const obj = {};
        data.forEach(item => {
          if (obj[item.stage]) {
            obj[item.stage].push(
              pickBy({
                node: item.node,
                name: item.nodeName,
                time: item.nodeDate,
                refCode: item.refCode || ""
              })
            );
          } else {
            obj[item.stage] = [
              pickBy({
                node: item.node,
                name: item.nodeName,
                time: item.nodeDate,
                refCode: item.refCode || ""
              })
            ];
          }
        });
        this.caseProgressObj = obj;
      });
    },
    getPageData() {
      this.isLoading = true;
      this.braceCaseProgressNodeList = JSON.parse(
        JSON.stringify(this.braceList)
      );
      submitAndTipsFlag(this.caseId).then(res => {
        this.reissueFlag = res;
      });
      getDictList("case.next_status").then(data => {
        const nextStatusObj = {};
        data.forEach(item => {
          nextStatusObj[item.key] = item.value;
        });
        this.nextStatusObj = nextStatusObj;
        this.getCasesDetail().then(() => {
          if (this.details.nextStatus === "to_deliver") {
            // this.getContinueDeliveryDetail();
            let { receiveAddress } = this.details;
            this.clinicDetail = receiveAddress;
          }
          setTimeout(() => {
            if (this.isMailModal) {
              this.curePlanDetail();
              this.sendExpress();
            }
          }, 500);
          PublicLogisticsCasePage({
            caseId: this.caseId,
            generateFlag: "1",
            refType: "2",
            pageNum: 1,
            pageSize: 2,
            pageType: "logistics"
          }).then(data => {
            this.logisticsList = data.list;
          });
        });
      });
    }
  },
  created() {
    this.HANDLE_MSG();
    document.title =
      localStorage.getItem("windowName") ||
      this.$t("casesDetail.casesDetail.title");
    this.seRemove();
    this.caseId = this.queryCaseId;
    this.curePlanId = this.queryCurePlanId;
    this.getPageData();
    this.getCaseCureNoteList();
    this.queryStageProgressList();
  },
  mounted() {
    console.log(this.details.cureNoteCount || 0);
    this.showViewInitNotice = shouldShowNotice(this.noticeKey);
    sessionStorage.removeItem("logisticsId");
    let erd = elementResizeDetectorMaker();
    erd.listenTo(this.$refs["headLeft"], element => {
      this.headRightUl = element.offsetHeight;
    });
  },
  components: {
    ConfirmModal,
    FullLoading,
    TheDelivery,
    ExBirdie,
    ContinueShipping,
    initNoticeCase
  }
};
</script>

<style lang="scss" scoped>
.design-dialog {
  .design-title {
    transform: translateY(-100%);
    padding: 0 0.2rem;
    display: flex;
    height: 24px;
    align-items: flex-end;
    font-size: 18px;
    color: #333333;
    width: fit-content;

    span {
      font-size: 14px;
      color: #999999;
      margin-left: 20px;
    }
  }
}

.stage-progress {
  height: 132px;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
  padding: 4px 12px;

  .stage-progress-content {
    display: flex;
    padding: 8px 0;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    white-space: nowrap;
    flex-wrap: no-wrap;
  }

  .stage-item,
  .to-be-confirmed {
    cursor: pointer;
    min-width: 288px;
    height: 100%;
    margin-right: 12px;
    box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.12);
    border-radius: 6px;
    padding: 16px;
    position: relative;
    transition: 0.2s linear;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .stage-bg {
      width: 80px;
      height: auto;
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .item-header {
      width: 100%;
      display: flex;
      align-items: flex-end;
      font-size: 18px;
      line-height: 22px;
      color: #333;

      span {
        margin-left: 6px;
        font-size: 12px;
        line-height: 16px;
        color: orange;
      }

      .tips-icon {
        width: 12px;
        height: 12px;
        margin-left: 16px;
      }

      .status {
        height: 24px;
        margin-left: 16px;
        border-radius: 12px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e5e5e5;
        color: #999;
        font-size: 12px;
      }
    }

    .stage-info {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 18px;
      color: #666;

      .design-date {
        margin-right: 12px;
      }
    }

    &:hover {
      box-shadow: 8px 8px 8px 0px rgba(51, 51, 51, 0.12);
    }
  }

  .to-be-confirmed {
    .item-header {
      .status {
        background-color: #ed4027;
        color: #fff;
      }
    }
  }
}

.suspended-content {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 36px;
    height: 36px;
  }

  .suspended-title {
    font-size: 18px;
    padding: 0 32px;
    margin: 12px 0 24px;
  }

  .suspended-tips {
    color: #999999;
    padding: 0 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;

    .tips-label {
      padding-top: 0.02rem;
      white-space: nowrap;
    }

    .tips-value {
      div {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
      }
    }
  }

  .suspended-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .submit-btn,
    .cancel-btn {
      cursor: pointer;
      width: 96px;
      height: 36px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
    }

    .submit-btn {
      background-color: #fcc80e;
    }

    .cancel-btn {
      border: 1px solid #e5e5e5;
      margin-right: 32px;
    }

    .submit-btn,
    .cancel-btn {
      cursor: pointer;
      width: 96px;
      height: 36px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
    }

    .submit-btn {
      background-color: #fcc80e;
    }

    .cancel-btn {
      border: 1px solid #e5e5e5;
      margin-right: 32px;
    }
  }
}

.switch-veneer-content {
  padding: 0 20px;
  display: flex;
  align-items: center;

  img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
}

.switch-veneer-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .submit-btn,
  .cancel-btn {
    cursor: pointer;
    width: 96px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }

  .submit-btn {
    background-color: #fcc80e;
  }

  .cancel-btn {
    border: 1px solid #e5e5e5;
    margin-right: 32px;
  }

  .submit-btn,
  .cancel-btn {
    cursor: pointer;
    width: 96px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
  }

  .submit-btn {
    background-color: #fcc80e;
  }

  .cancel-btn {
    border: 1px solid #e5e5e5;
    margin-right: 32px;
  }
}

.case-list {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  border-radius: 6px 0 0 6px;
  top: 86px;
  left: 195px;
  width: 40px;
  font-size: 16px;
  overflow: hidden;

  .case-list-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .case-list-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 5px;
    word-spacing: 5px;
    writing-mode: vertical-lr;
    overflow: hidden;
    white-space: nowrap;
    // text-orientation: upright;
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      width: 24px;
      height: 1px;
      background-color: #e5e5e5;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
    }

    &:last-child::after {
      display: none;
    }
  }

  .active-list-item {
    cursor: default;
    background-color: #fff;
    color: #fcc80e;

    &::after {
      display: none;
    }
  }

  .case-list-more {
    cursor: pointer;
    width: 40px;
    height: 30px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
    }
  }
}

.more-list-content {
  padding: 10px 0;
  width: 150px;
  max-height: 220px;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  .more-list-item {
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: #f4f4f4;
    }
  }
}

.cqbl {
  background-image: url("../../../common/imgs/case/cqbl.png");
}

.cqbl_gray {
  background-image: url("../../../common/imgs/case/cqbl_gray.png");
}

.jxfh {
  background-image: url("../../../common/imgs/case/jxfh.png");
}

.jxfh_black {
  background-image: url("../../../common/imgs/case/jxfh_black.png");
}

.jxfh_gray {
  background-image: url("../../../common/imgs/case/jxfh_gray.png");
}

.qdmbfa {
  background-image: url("../../../common/imgs/case/qdmbfa.png");
}

.qdmbfa_black {
  background-image: url("../../../common/imgs/case/qdmbfa_black.png");
}

.qdmbfa_gray {
  background-image: url("../../../common/imgs/case/qdmbfa_gray.png");
}

.qdzlfa {
  background-image: url("../../../common/imgs/case/qdzlfa.png");
}

.qdzlfa_gray {
  background-image: url("../../../common/imgs/case/qdzlfa_gray.png");
}

.qdzlfa_black {
  background-image: url("../../../common/imgs/case/qdzlfa_black.png");
}

.zirdt {
  background-image: url("../../../common/imgs/case/zirdt.png");
}

.zirdt_gray {
  background-image: url("../../../common/imgs/case/zirdt_gray.png");
}

.zirdt_black {
  background-image: url("../../../common/imgs/case/zirdt_black.png");
}

.scbcq {
  background-image: url("../../../common/imgs/case/scbcq.png");
}

.scbcq_gray {
  background-image: url("../../../common/imgs/case/scbcq_gray.png");
}

.scbcq_black {
  background-image: url("../../../common/imgs/case/scbcq_black.png");
}

.tjzlb {
  background-image: url("../../../common/imgs/case/tjzlb.png");
}

.tjzlb_gray {
  background-image: url("../../../common/imgs/case/tjzlb_gray.png");
}

.tjzlb_black {
  background-image: url("../../../common/imgs/case/tjzlb_black.png");
}

.wcbl {
  background-image: url("../../../common/imgs/case/wcbl.png");
}

.wcbl_gray {
  background-image: url("../../../common/imgs/case/wcbl_gray.png");
}

.wcbl_black {
  background-image: url("../../../common/imgs/case/wcbl_black.png");
}

.jdtz {
  background-image: url("../../../common/imgs/case/jdtz.png");
}

.jdtz_gray {
  background-image: url("../../../common/imgs/case/jdtz_gray.png");
}

.jdtz_black {
  background-image: url("../../../common/imgs/case/jdtz_black.png");
}

.ztfw {
  background-image: url("../../../common/imgs/case/ztfw.png");
}

.ztfw_gray {
  background-image: url("../../../common/imgs/case/ztfw_gray.png");
}

.yjgxj {
  background-image: url("../../../common/imgs/case/yjgxj.png");
}

.yjgxj_black {
  background-image: url("../../../common/imgs/case/yjgxj_black.png");
}

.yjgxj_gray {
  background-image: url("../../../common/imgs/case/yjgxj_gray.png");
}

.zjsc {
  background-image: url("../../../common/imgs/case/zjsc.png");
}

.zjsc_black {
  background-image: url("../../../common/imgs/case/zjsc_black.png");
}

.zjsc_gray {
  background-image: url("../../../common/imgs/case/zjsc_gray.png");
}

.jjyt_gray {
  background-image: url("../../../common/imgs/case/jjyt_gray.png");
}

.jjyt {
  background-image: url("../../../common/imgs/case/jjyt.png");
}

.yjgxj_black {
  background-image: url("../../../common/imgs/case/yjgxj_black.png");
}

.ztm {
  background-image: url("../../../common/imgs/case/ztm.png");
}

.ztm_gray {
  background-image: url("../../../common/imgs/case/ztm_gray.png");
}

.ztm_black {
  background-image: url("../../../common/imgs/case/ztm_black.png");
}

.gjjzq {
  background-image: url("../../../common/imgs/case/gjjzq.png");
}

.gjjzq_black {
  background-image: url("../../../common/imgs/case/gjjzq_black.png");
}

.hdjzq {
  background-image: url("../../../common/imgs/case/hdjzq.png");
}

.hdjzq_black {
  background-image: url("../../../common/imgs/case/hdjzq_black.png");
}

.yxjzq {
  background-image: url("../../../common/imgs/case/yxjzq.png");
}

.yxjzq_black {
  background-image: url("../../../common/imgs/case/yxjzq_black.png");
}

.continue_deliver {
  height: auto;
  padding-left: 0.63rem;

  .process_step {
    margin-bottom: 0.99rem;
  }
}

.target {
  color: $main_theme_color_333;
  width: 100%;
  height: 0.54rem;
  display: flex;
  font-size: 0.16rem;
  cursor: pointer;
  padding: 16px 0.4rem;

  .target_title {
    width: 174px;
  }

  .target_time {
    width: 102px;
    margin-left: 176px;
  }

  .target_state {
    width: 50px;
    margin-left: 170px;
  }
}

.target:hover {
  background: #f9f9f9;
  transition: 0.3s all;
}

.threeD_cancel {
  width: 96px;
  height: 36px;
  background: #fcc80e;
  border-radius: 4px;
  font-size: 14px;
  color: $main_theme_color_333;
  position: absolute;
  right: 0;
  top: 24px;
}

.cases_detail {
  width: 13.44rem;
  margin: 0 auto;
  padding-top: 0.53rem;
  color: $main_theme_color_333;
  font-size: 0.16rem;

  .cases_detail_tit {
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-bottom: 0.13rem;
    display: flex;
    align-items: center;

    .tit_strong {
      font-size: 0.2rem;
      margin-right: 0.3rem;
    }

    .tit_p {
      font-size: 0.16rem;
      padding-left: 0.1rem;
      border-left: 0.01rem solid #b5b5b5;
    }
  }

  .detail_head {
    display: flex;
    margin-bottom: 0.2rem;
    justify-content: space-between;

    .head_left {
      background-color: #fff;
      padding-top: 30px;
      padding-left: 30px;
      // border-radius: 0.08rem;
      border-radius: 0 8px 8px 0;
      margin-right: 0.2rem;
      flex: 1;

      .head_left_top {
        display: flex;
        padding-bottom: 20px;

        .head_l_left {
          width: 1.2rem;
          height: 1.2rem;
          border-radius: 100%;
          margin-right: 0.2rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .head_l_right {
          flex: 1;

          span {
            display: inline-block;
          }

          .l_right_head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0.13rem;

            .l_right_head_l {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .l_right_head_l_n {
                font-size: 0.2rem;
                display: flex;
                align-items: center;

                .l_right_head_l_n_userName {
                  min-width: 1rem;
                  max-width: 1.7rem;
                  line-height: 0.25rem;
                }

                .head_pic {
                  margin-left: 0.09rem;
                  display: inline-block;
                  width: 0.16rem;
                  height: 0.16rem;
                  background-size: 100% 100%;
                }
              }

              .case_status {
                position: absolute;
                right: 1.5rem;
                font-size: 16px;
                color: $main_theme_color;
              }

              .head_h5 {
                font-size: 0.22rem;
                font-weight: bold;
                margin-left: 1.1rem;
                border-left: 0.02rem solid $main_theme_color;
                padding-left: 0.1rem;
              }
            }

            .l_right_head_r {
              background-color: $main_theme_color;
              color: #fff;
              border-radius: 1rem;
              width: 1.22rem;
              height: 0.4rem;
              line-height: 0.4rem;
              text-align: center;
              cursor: pointer;
            }
          }

          .l_right_p {
            color: $main_theme_color_333;
            display: flex;
            font-size: 0.16rem;

            &:nth-of-type(1) {
              margin-bottom: 0.13rem;
            }

            &:nth-of-type(2) {
              margin-bottom: 0.2rem;
            }

            .l_right_p_c {
              display: flex;
              flex-wrap: wrap;
              flex: 1;
              height: 16px;
              transition: all 0.2s;
              overflow: hidden;
            }

            .l_right_p_c_show {
              height: 40px !important;
              transition: all 0.2s;
            }

            .r_lc_icon {
              width: 0.2rem;
              height: 0.2rem;
              margin-right: 0.06rem;
              background-size: 100% 100%;
              background-image: url("/static/images/teeth_icon.png");
            }

            .r_sl_sp {
              border-radius: 18px;
              margin-right: 20px;
              margin-bottom: 10px;
              font-size: 14px;
            }
          }

          .show_all {
            width: 70px;
            margin-left: 108px;
          }
        }
      }

      .head_left_foo {
        margin-top: 0.23rem;

        .foo_p_icon {
          display: inline-block;
          width: 0.18rem;
          height: 0.18rem;
          background-size: 100% 100%;
          background-image: url("/static/images/white_bi.png");
          margin-right: 0.1rem;
        }

        .add_remark {
          width: 100%;
          display: flex;
          position: relative;

          .text_box {
            border-radius: 0.06rem;
            padding: 0.13rem 0.15rem;
            height: 0.22rem;
            width: 100%;
            margin-right: 0.2rem;
            position: relative;

            textarea {
              width: 100%;
              height: 100%;
            }
          }
        }

        .rem_btns {
          width: 6.28rem;
          display: flex;
          align-items: center;
          flex-flow: row-reverse;

          .bc_btn {
            width: 96px;
            height: 36px;
            background: #fcc80e;
            border-radius: 4px;
          }

          .qx_btn {
            width: 96px;
            height: 36px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #bbbbbb;
          }
        }

        .edit_remark {
          font-size: 0.14rem;
          display: flex;

          .remark_p {
            width: 3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .edit_icon {
            display: inline-block;
            width: 0.17rem;
            height: 0.17rem;
            background-size: 100% 100%;
            background-image: url("/static/images/add_emarks.png");
            margin-right: 0.06rem;
          }

          .edit_txt {
            margin-left: 0.2rem;
            color: $main_theme_color;
            cursor: pointer;
            font-size: 0.16rem;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .head_left_min_height {
      min-height: 280px;
    }

    .head_right {
      border-radius: 0.06rem;
      width: 4rem;

      .head_right_tit {
        background-color: #fafafa;
        display: flex;
        align-items: center;
        color: $main_theme_color_333;
        font-size: 0.18rem;
        height: 0.74rem;
        cursor: pointer;

        .head_right_p {
          background-color: white;
          color: $main_theme_color;
          width: 3.2rem;
          height: 0.74rem;
          padding: 0 0.2rem;
          border-radius: 0 0.05rem 0.05rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .r_h5_icon {
          width: 0.8rem;
          height: 0.74rem;
          line-height: 0.26rem;
          display: flex;
          text-align: center;
          border-radius: 6px;
          align-items: center;
          justify-content: center;
          background-color: #38383a;
          color: #fff;
        }

        .r_h5_en {
          width: 1.6rem;
        }
      }

      .head_right_ul {
        color: $main_theme_color_333;
        background-color: #fff !important;
        border-radius: 6px;

        .h_r_single {
          height: 54px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          padding: 0 30px;
          transition: all 0.2s;

          &:hover .h_r_s_left {
            border-color: #adadad;
          }

          .h_r_s_left {
            border-bottom: 0.01rem solid transparent;
            font-size: 0.16rem;
            color: $main_theme_color_333;
          }

          .h_r_s_right {
            display: flex;
            align-items: baseline;

            .s_r_strong {
              color: $main_theme_color_333;
              font-size: 0.16rem;
              margin-top: 2px;
            }

            .s_r_to {
              margin-left: 0.04rem;
              font-size: 0.16rem;
            }
          }
        }

        .h_r_single:hover {
          background: #f9f9f9;
          transition: all 0.2s;
        }
      }
    }
  }

  .detail_step {
    background-color: #fff;
    height: 166px;
    padding: 30px 70px;
    border-radius: 0.08rem;
    margin-bottom: 0.2rem;
    position: relative;

    .detail_step_icon {
      display: block;
      width: 0.48rem;
      height: 0.16rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    span {
      display: inline-block;
    }

    .step_con_ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.1rem;

      .step_con_li {
        width: 174px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .con_li_left {
          min-width: 0.72rem;
          height: 0.72rem;
          margin-right: 0.1rem;
          background-size: 100% 100%;
        }

        .def_step01 {
          background-image: url("../../../common/imgs/case/step01.png");
        }

        .def_step01_active {
          background-image: url("../../../common/imgs/case/step01_active.png");
        }

        .def_step02 {
          background-image: url("../../../common/imgs/case/step02.png");
        }

        .def_step02_active {
          background-image: url("../../../common/imgs/case/step02_active.png");
        }

        .def_step03 {
          background-image: url("../../../common/imgs/case/step03.png");
        }

        .def_step03_active {
          background-image: url("../../../common/imgs/case/step03_active.png");
        }

        .def_step04 {
          background-image: url("../../../common/imgs/case/step04.png");
        }

        .def_step04_active {
          background-image: url("../../../common/imgs/case/step04_active.png");
        }

        .def_step05 {
          background-image: url("../../../common/imgs/case/step05.png");
        }

        .def_step05_active {
          background-image: url("../../../common/imgs/case/step05_active.png");
        }

        .con_li_right {
          color: $main_theme_color_333;

          .con_li_h5 {
            margin-bottom: 0.1rem;
            font-size: 0.18rem;
          }

          .con_li_p {
            font-size: 0.16rem;
          }
        }
      }
    }

    .step_default_ul {
      .tag_li {
        &:last-child {
          .tag_li_line {
            display: none;
          }
        }
      }
    }

    .step_tag_ul {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: 20px;

      /*justify-content: center;*/
      .tag_li {
        display: flex;
        align-items: center;
      }

      .tag_li_icon {
        width: 0.24rem;
        height: 0.24rem;
        background-size: 100% 100%;
        border-radius: 100%;
        color: $main_theme_color_999;
        text-align: center;
        border: 2px solid #e5e5e5;
        line-height: 0.24rem;
      }

      .tag_li_icon_active {
        background-image: url("/static/images/step_c_icon.png") !important;
      }

      .tag_li_icon_wait {
        border: 1px solid #fcc80e;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 0.12rem;
          height: 0.12rem;
          border-radius: 50%;
          background-color: #fcc80e;
        }
      }

      .tag_li_border_active {
        border-color: #fcc80e;
        color: #fcc80e;
      }

      .tag_li_line {
        width: 3.2rem;
        height: 0.02rem;
        background-color: #e6e6e6;
      }

      .tag_li_line_ok {
        background-color: $main_theme_color;
      }
    }
  }

  .detail_con {
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-between;

    .detail_con_left {
      margin-right: 0.2rem;
      width: 4.4rem;

      .con_left_ul {
        display: flex;
        flex-direction: column;

        span {
          display: inline-block;
        }

        .con_l_single {
          background-color: #fff;
          border-radius: 0.05rem;
          margin-bottom: 0.2rem;

          .con_l_s {
            color: $main_theme_color_333;
            height: 58px;
            font-size: 0.18rem;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e5e5e5;
            position: relative;
            padding-left: 30px;
            padding-right: 30px;
            justify-content: space-between;

            .title-box {
              display: flex;
              align-items: center;
              justify-items: center;

              .long_string {
                width: 4px;
                height: 26px;
                background: #fcc80e;
                margin-right: 10px;
              }
            }

            .extra-box {
              width: 15px;
              height: 15px;
              cursor: pointer;

              img {
                width: 100%;
              }
            }
          }

          .con_l_s_box {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding: 30px 43px 30px 30px;

            .l_s_box_single {
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              cursor: pointer;
              position: relative;
              width: 33%;
              height: 1rem;
              line-height: 0.2rem;

              .brace-tips,
              .gray-brace-tips {
                width: 24px;
                height: 24px;
                position: absolute;
                background: url("../../../common/imgs/tips-error.png") no-repeat
                  center;
                background-size: 100% 100%;
                top: -10px;
                right: 15px;
                z-index: 11;
              }

              .gray-brace-tips {
                background: url("../../../common/imgs/gray-tips-error.png")
                  no-repeat center;
                background-size: 100% 100%;
              }

              &:hover .show_tip {
                display: block;
              }

              .mask_box {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 9;
              }

              .show_tip {
                display: none;
                position: absolute;
                font-size: 0.12rem;
                top: -50%;
                left: 50%;
                transform: translate(-50%, -20%);
                width: 0.8rem;
                height: 0.42rem;
                line-height: 0.37rem;
                text-align: center;
                background-image: url("/static/images/tips_bg.png");
                background-size: 100% 100%;
                color: $main_theme_color_999;
              }

              .l_s__p {
                font-size: 0.14rem;
                text-align: center;
                position: absolute;
                top: 0.45rem;
              }

              .l_s__icon {
                background-size: 100% 100%;
                width: 0.32rem;
                height: 0.32rem;
                position: absolute;
                top: 0.1rem;
              }

              .yq_icon {
                background-image: url("/static/images/invitation_icon.png");
              }

              .yt_icon {
                background-image: url("/static/images/wear_icon.png");
              }

              .yt_icon_h {
                background-image: url("/static/images/web_yatao_text2.png");
              }

              .yt_icon_complete {
                background-image: url("/static/images/wear_icon_b.png");
              }

              .yz_icon {
                background-image: url("/static/images/advice_icon.png");
              }

              .cases_d_h_s01 {
                background-image: url("/static/images/cases_d_h_s01.png");
              }

              .cases_d_h_s02 {
                background-image: url("/static/images/web_fix_text2.png");
              }

              .cases_d_h_s03 {
                background-image: url("/static/images/cases_d_h_s02.png");
              }

              .cases_d_h_s04 {
                background-image: url("/static/images/cases_d_h_s03.png");
              }

              .cases_d_h_s05 {
                background-image: url("/static/images/cases_d_h_s02.png");
              }

              .cases_d_h_s06 {
                background-image: url("/static/images/cases_d_h_s04.png");
              }

              .cases_d_h_s07 {
                background-image: url("/static/images/cases_d_h_s05.png");
              }

              .cases_d_h_s08 {
                background-image: url("/static/images/cases_d_h_s06.png");
              }

              .cases_d_h_s09 {
                background-image: url("/static/images/cases_d_h_s07.png");
              }

              .cases_d_h_s010 {
                background-image: url("/static/images/cases_d_h_s08.png");
              }

              .zs_icon {
                background-image: url("/static/images/cases_d_b_s09.png");
              }

              .cases_d_y_s01 {
                background-image: url("/static/images/cases_d_y_s01.png");
              }

              .cases_d_y_s02 {
                background-image: url("/static/images/cases_d_y_s10.png");
              }

              .cases_d_y_s03 {
                background-image: url("/static/images/cases_d_y_s02.png");
              }

              .cases_d_y_s04 {
                background-image: url("/static/images/cases_d_y_s03.png");
              }

              .cases_d_y_s05 {
                background-image: url("/static/images/cases_d_y_s02.png");
              }

              .cases_d_y_s06 {
                background-image: url("/static/images/cases_d_y_s04.png");
              }

              .cases_d_y_s07 {
                background-image: url("/static/images/cases_d_y_s05.png");
              }

              .cases_d_y_s08 {
                background-image: url("/static/images/cases_d_y_s06.png");
              }

              .cases_d_y_s09 {
                background-image: url("/static/images/cases_d_y_s07.png");
              }

              .cases_d_y_s010 {
                background-image: url("/static/images/cases_d_y_s08.png");
              }

              .cases_d_b_s01 {
                background-image: url("/static/images/cases_d_b_s01.png");
              }

              .cases_d_b_s02 {
                background-image: url("/static/images/web_fix_text1.png");
              }

              .cases_d_b_s03 {
                background-image: url("/static/images/cases_d_b_s02.png");
              }

              .cases_d_b_s04 {
                background-image: url("/static/images/cases_d_b_s03.png");
              }

              .cases_d_b_s05 {
                background-image: url("/static/images/cases_d_b_s02.png");
              }

              .cases_d_b_s06 {
                background-image: url("/static/images/cases_d_b_s04.png");
              }

              .cases_d_b_s07 {
                background-image: url("/static/images/cases_d_b_s05.png");
              }

              .cases_d_b_s08 {
                background-image: url("/static/images/cases_d_b_s06.png");
              }

              .cases_d_b_s09 {
                background-image: url("/static/images/cases_d_b_s07.png");
              }

              .cases_d_b_s010 {
                background-image: url("/static/images/cases_d_b_s08.png");
              }

              .cases_d_b_s011 {
                background-image: url("/static/images/cases_d_b_s011.png");
              }
            }
          }
        }
      }
    }

    .detail_con_right {
      flex: 1;

      .con_r_single {
        margin-bottom: 0.2rem;
        background-color: #fff;
        border-radius: 0.06rem;

        .con_r__header {
          height: 58px;
          font-size: 0.18rem;
          border-bottom: 1px solid #e5e5e5;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-left: 30px;
          padding-right: 30px;

          .long_string {
            width: 4px;
            height: 26px;
            background: #fcc80e;
            margin-right: 10px;
          }

          .con_r__sp {
            cursor: pointer;
            color: $main_theme_color;
            font-size: 0.16rem;
            float: right;
          }
        }

        .con_r__box {
          padding: 20px 30px 30px 30px;

          .con_r__box_l {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #f4f4f4;
            padding: 20px;
            border-radius: 4px;

            p {
              color: $main_theme_color_333;
              font-size: 0.14rem;
              line-height: 0.24rem;
            }
          }

          .con_r__box_r {
            font-size: 0.14rem;
            color: $main_theme_color_red;
            cursor: pointer;
            white-space: nowrap;
          }
        }

        .con_no_data {
          padding: 0.18rem 0;
          text-align: center;
          color: #999999;
        }
      }
    }

    .con_cases_box_add {
      margin-top: 0.2rem;
      border-bottom: 0.01rem solid $main_theme_color_bbb;
    }

    .con_cases_box {
      padding: 20px 60px 20px 50px;

      .cases_single {
        margin-bottom: 0.18rem;
      }

      .cases_s_tit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        height: 42px;
        font-size: 0.16rem;
        color: $main_theme_color_333;
        padding: 10px;

        span {
          display: inline-block;
          width: 0.24rem;
          height: 0.24rem;
          background-size: 100% 100%;
        }

        .cases_s_tit_d_icon {
          background-image: url("../../../common/imgs/case/arrow_down.png");
        }

        .cases_s_tit_u_icon {
          background-image: url("../../../common/imgs/case/arrow_up.png");
        }
      }

      .cases_s_con {
        padding-left: 0.2rem;

        .cases_s_con_p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: $main_theme_color_333;
          font-size: 0.14rem;
          margin-bottom: 0.1rem;

          .cases_s_con_sp {
            color: $main_theme_color;
            cursor: pointer;
            font-size: 0.14rem;
          }
        }
      }
    }
  }
}

.w15fr {
  width: 1.5rem;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w1fr {
  width: 0.8rem;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flex_column {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.history_list {
  height: 3rem;
  width: 100%;

  .history_list_item {
    display: flex;
    margin-bottom: 0.2rem;

    div {
      width: 3.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 0.2rem;
      margin-right: 0.2rem;
    }
  }
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ table th,
table td {
  border-bottom: none !important;
}

/deep/ .dialog-footer {
  display: flex;
  //flex-flow: row-reverse;
}

/deep/ .el-table__row {
  cursor: pointer;
}

.look_history {
  margin-top: -0.26rem;
}

.remark_input {
  width: 6.28rem;
  position: absolute;
  height: 0.22rem;
  top: 0;
  left: 0;
}

/deep/ .el-dialog__body {
  padding: 10px 0 24px 0;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  color: $main_theme_color_333;
}

.tip {
  cursor: pointer;
}

.dia-header {
  height: 58px;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-bottom: 1px solid #e5e5e5;
  color: #333333;

  img {
    cursor: pointer;
  }
}

.dia-content {
  //height: 478px;
  //padding: 20px;
  //overflow-y: auto;
}

.dia-footer {
  padding: 0 20px;
  height: 56px;
  box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .confirm,
  .cancel {
    cursor: pointer;
    width: 96px;
    height: 36px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #333333;
  }

  .cancel {
    border: 1px solid #bbbbbb;
  }

  .confirm {
    background-color: #fcc80e;
    margin-left: 32px;
  }
}

.suspended-service-one {
  /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog__body {
    padding: 0;
  }
}
</style>
